import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import actionTypes from "../../../../state/global-state/action-types";
import UserUpdateFormInput from "../../../../models/form-inputs/user-update-input";
import userService from "../../../../services/api/user-service";
import { invalidateQuery } from "../../../../queries/query-utils";
import queryNames from "../../../../queries/query-names";
import EditUserForm from "../edit/EditUserForm";

export default function CreateUser() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { dispatch } = useContext(GlobalStateContext);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SETTINGS_BREADCRUMBS,
      payload: [
        {
          name: "Account Settings",
          to: "/settings",
        },
        {
          name: "Team",
          to: "/settings/team",
        },
        {
          name: "Invite",
          to: "/settings/team/create",
        },
      ],
    });
  }, [dispatch]);

  const onSubmit = (input: UserUpdateFormInput) => {
    setIsSubmitting(true);

    userService
      .create(input)
      .then(() => {
        invalidateQuery([queryNames.users]);
        navigate(-1);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div className="flex flex-col bg-white shadow sm:rounded-lg min-h-[465px] px-4 py-5 sm:px-6">
        <EditUserForm
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          isEdit={false}
        />
      </div>
    </>
  );
}
