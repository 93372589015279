import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import InputField from "../../../components/form/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "../../../components/common/Button";
import { useState } from "react";
import { autoSignIn, signInWithRedirect, signUp } from "aws-amplify/auth";
import { XCircleIcon } from "@heroicons/react/20/solid";
import Spinner from "../../../components/common/Spinner";
import PasswordField from "../../../components/form/PasswordField";
import ValidationMessages from "../../../data/validation-messages";

const schema = yup
  .object({
    name: yup
      .string()
      .max(120, ValidationMessages.FullNameLength(120))
      .required(ValidationMessages.FullNameRequired),
    email: yup
      .string()
      .email(ValidationMessages.EmailFormat)
      .required(ValidationMessages.EmailRequired),
    password: yup.string().required(ValidationMessages.PasswordRequired),
    repeatPassword: yup
      .string()
      .required(ValidationMessages.RepeatPasswordRequired)
      .oneOf([yup.ref("password")], ValidationMessages.PasswordsMustMatch),
  })
  .required();

interface FormInput {
  name?: string;
  email?: string;
  password?: string;
  repeatPassword?: string;
}

export default function Signup() {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [submittingFacebook, setSubmittingFacebook] = useState(false);
  const [submittingGoogle, setSubmittingGoogle] = useState(false);
  const [error, setError] = useState("");

  const form = useForm<FormInput>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const submitForm = () => {
    form.handleSubmit(onSubmit)();
  };

  const onSubmit = async (input: FormInput) => {
    console.log(input);
    setError("");

    try {
      setSubmitting(true);

      const { nextStep } = await signUp({
        username: input.email,
        password: input.password,
        options: {
          userAttributes: {
            email: input.email,
            name: input.name,
          },
          // optional
          autoSignIn: true, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        },
      });

      await handleSignUp(nextStep.signUpStep);
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const facebookLogin = async () => {
    setError("");

    try {
      setSubmittingFacebook(true);
      signInWithRedirect({ provider: "Facebook" });
    } catch (e) {
      setError(e.message);
      setSubmittingFacebook(false);
    }
  };

  const googleLogin = async () => {
    setError("");

    try {
      setSubmittingGoogle(true);
      await signInWithRedirect({ provider: "Google" });
    } catch (e) {
      setError(e.message);
      setSubmittingGoogle(false);
    }
  };

  const handleSignUp = async (nextStep: string) => {
    console.log(nextStep);

    switch (nextStep) {
      case "DONE":
        navigate("/scheduler");
        break;

      case "CONFIRM_SIGN_UP":
        navigate(`/confirm-signup?email=${form.getValues().email}`);
        break;

      case "COMPLETE_AUTO_SIGN_IN":
        await handleAutoSignIn();
        break;
    }
  };

  const handleAutoSignIn = async () => {
    try {
      const signInOutput = await autoSignIn();
      if (signInOutput.nextStep.signInStep === "DONE") {
        navigate("/scheduler");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <div className="text-center">
        <h2 className="mt-8 text-2xl/9 font-bold tracking-tight text-gray-900">
          Let's get started!
        </h2>
        <p className="mt-2 text-sm text-gray-500">
          Already have an account?{" "}
          <Link
            to="/login"
            className="font-medium text-primary-600 hover:text-gray-900 transition-all cursor-pointer"
          >
            Sign in
          </Link>{" "}
        </p>
      </div>

      <div className="mt-10">
        <div>
          {error && (
            <div className="mb-6 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="shrink-0">
                  <XCircleIcon
                    aria-hidden="true"
                    className="size-5 text-red-400"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <InputField label="Full name" name="name" formReturn={form} />
            </div>

            <div>
              <InputField label="Email" name="email" formReturn={form} />
            </div>

            <div>
              <PasswordField
                label="Password"
                name="password"
                formReturn={form}
              />
            </div>

            <div>
              <PasswordField
                label="Confirm password"
                name="repeatPassword"
                formReturn={form}
              />
            </div>

            <div>
              <Button
                onClick={submitForm}
                text="Create Account"
                color="brand"
                variant="solid"
                loading={submitting}
                type="submit"
                className="w-full"
              />
            </div>
          </form>
        </div>

        <div className="mt-10">
          <div className="relative">
            <div
              aria-hidden="true"
              className="absolute inset-0 flex items-center"
            >
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm/6 font-medium">
              <span className="bg-white px-6 text-gray-900">
                Or continue with
              </span>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-2 gap-4">
            <button
              onClick={googleLogin}
              disabled={submittingFacebook || submittingGoogle}
              className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
            >
              <svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
                <path
                  d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                  fill="#EA4335"
                />
                <path
                  d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                  fill="#4285F4"
                />
                <path
                  d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                  fill="#FBBC05"
                />
                <path
                  d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                  fill="#34A853"
                />
              </svg>
              <span className="text-sm/6 font-medium">Google</span>

              {submittingGoogle && <Spinner size="4" />}
            </button>

            <button
              onClick={facebookLogin}
              disabled={submittingFacebook || submittingGoogle}
              className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="100"
                height="100"
                viewBox="0 0 48 48"
                className="size-6 fill-[#0866ff]"
              >
                <path d="M44,24c0,10.13-7.52,18.5-17.29,19.81V29h2.49c1.48,0,2.74-1.08,2.97-2.54l0.53-3.42h-5.99v-1.87	c0-2.18,0.71-4.12,2.75-4.12h1.29c1.1,0,2-0.9,2-2v-2.59c-0.58-0.08-1.8-0.25-4.11-0.25c-4.81,0-7.63,2.54-7.63,8.34v2.49h-1.95	c-1.66,0-3,1.34-3,3V29h4.95v14.78C11.38,42.33,4,34.03,4,24C4,12.95,12.95,4,24,4S44,12.95,44,24z"></path>
              </svg>

              <span className="text-sm/6 font-medium">Facebook</span>

              {submittingFacebook && <Spinner size="5" />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
