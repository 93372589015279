import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../../queries/query-names";
import stripeService from "../../../../services/api/stripe-service";
import Spinner from "../../../../components/common/Spinner";

import Button from "../../../../components/common/Button";
import cardMappings from "../../../../data/payment-cards";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

export default function PaymentMethods() {
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);

  const { data: paymentMethods, isLoading } = useQuery({
    queryKey: [queryNames.stripePaymentMethods],
    queryFn: stripeService.getPaymentMethods,
  });

  const openBillingPortal = async () => {
    setIsLoadingPortal(true);

    try {
      const returnUrl = `${window.location.origin}/settings/payment-methods`;
      const customerPortalSession = await stripeService.getCustomerPortal(
        returnUrl
      );

      window.location.href = customerPortalSession.url;
    } catch {
      setIsLoadingPortal(false);
    }
  };

  const openAddPaymentMethod = async () => {
    setIsLoadingPortal(true);

    try {
      const returnUrl = `${window.location.origin}/settings/payment-methods`;

      const customerPortalSession = await stripeService.getCustomerPortal(
        returnUrl,
        "payment_method_update"
      );

      window.location.href = customerPortalSession.url;
    } catch {
      setIsLoadingPortal(false);
    }
  };

  const manageComponent = (
    <div className="flex justify-start mt-2">
      <Button
        className="-ml-4"
        onClick={() => openBillingPortal()}
        target="_blank"
        variant="text"
        color="brand"
        text=""
        disabled={isLoadingPortal}
      >
        {isLoadingPortal ? (
          <div className="flex items-center gap-2">
            <span>Loading</span>
            <Spinner size="4" />
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <span>Manage</span>
            <ArrowTopRightOnSquareIcon className="w-4" />
          </div>
        )}
      </Button>
    </div>
  );

  const addNewComponent = (
    <div className="flex justify-start mt-2">
      <Button
        className="-ml-4"
        onClick={() => openAddPaymentMethod()}
        target="_blank"
        variant="text"
        color="brand"
        text=""
        disabled={isLoadingPortal}
      >
        {isLoadingPortal ? (
          <div className="flex items-center gap-2 mt-4">
            <span>Loading</span>
            <Spinner size="4" />
          </div>
        ) : (
          <div className="flex items-center gap-2 mt-4">
            <span>Add new payment method</span>
            <ArrowTopRightOnSquareIcon className="w-4" />
          </div>
        )}
      </Button>
    </div>
  );

  const content = isLoading ? (
    <div className="flex-1 h-full flex items-center justify-center">
      <Spinner size="5" />
    </div>
  ) : (
    <>
      <div className="flex-1 mt-4">
        {!paymentMethods.length ? (
          <>
            <div className="flex flex-col">
              <div className="text-sm text-gray-700">No payment methods</div>
              {addNewComponent}
            </div>
          </>
        ) : (
          <>
            <table>
              <tbody>
                {paymentMethods.map((paymentMethod, index) => (
                  <tr key={index} className="">
                    {paymentMethod.type == "card" && (
                      <>
                        <td className="">
                          <div className="flex items-center gap-2 text-sm font-medium text-gray-900 py-2">
                            <div>
                              <img
                                width="25"
                                src={
                                  cardMappings?.[paymentMethod?.card?.brand]
                                    ?.image ?? cardMappings.unknown.image
                                }
                              />
                            </div>
                            <span className="capitalize">
                              {paymentMethod.card?.brand}
                            </span>
                            <span className="-mt-1">....</span>
                            <span>{paymentMethod.card?.last4}</span>
                          </div>
                        </td>
                        <td className="px-8">
                          <div className="flex items-center text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                            Expires {paymentMethod.card?.expMonth}/
                            {paymentMethod.card?.expYear}
                          </div>
                        </td>
                      </>
                    )}

                    {paymentMethod.type === "link" && (
                      <>
                        <td className="">
                          <div className="flex items-center gap-2 text-sm font-medium text-gray-900 py-2">
                            <div>
                              <svg
                                className="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg"
                                height="20"
                                width="20"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path fill="#00D66F" d="M0 0h32v32H0z"></path>
                                <path
                                  fill="#011E0F"
                                  d="M15.144 6H10c1 4.18 3.923 7.753 7.58 10C13.917 18.246 11 21.82 10 26h5.144c1.275-3.867 4.805-7.227 9.142-7.914v-4.18c-4.344-.68-7.874-4.04-9.142-7.906Z"
                                ></path>
                              </svg>
                            </div>
                            <span className="capitalize">
                              {paymentMethod.link?.email}
                            </span>
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {manageComponent}
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className="divide-y divide-gray-200">
        <div className="space-y-1">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Payment Methods
          </h2>

          <p className="max-w-2xl text-sm text-gray-500">
            View & manage your payment methods.
          </p>
        </div>
        <div className="mt-4 min-h-20 flex flex-col">{content}</div>
      </div>
    </>
  );
}
