import {
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import CreatePost from "../../modules/scheduler/create-post/CreatePost";
import Post from "../../models/entities/post";
import DeleteDialog from "./DeleteDialog";
import useCurrentSocialSet from "../../hooks/useCurrentSocialSet";
import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import intercomService from "../../services/application/intercom-service";
import { XMarkIcon } from "@heroicons/react/24/outline";

export interface CreatePostDialogProps {
  onPostSubmitted: (posts: Post[]) => void;
  onClosed?: () => void;
}

export interface OpenComposerParams {
  editingPost?: Post;
  cloningPost?: Post;
  channelIds?: string[];
  scheduledAt?: Date;
}

const CreatePostDialog = forwardRef(
  ({ onPostSubmitted, onClosed }: CreatePostDialogProps, ref) => {
    const socialSet = useCurrentSocialSet();
    const navigate = useNavigate();
    const { isMd } = useBreakpoint("md");

    const [isOpen, setIsOpen] = useState(false);
    const [editingPost, setEditingPost] = useState<Post>(null);
    const [cloningPost, setCloningPost] = useState<Post>(null);
    const [channelIds, setChannelIds] = useState<string[]>([]);
    const [scheduledAt, setScheduledAt] = useState<Date>(null);

    const cancelButtonRef = useRef(null);
    const discardChangesDialogRef = useRef(null);

    // Expose the openDialog and closeDialog methods to parent components
    useImperativeHandle(ref, () => ({
      openDialog: ({
        editingPost,
        cloningPost,
        channelIds,
        scheduledAt,
      }: OpenComposerParams) => {
        if (!socialSet.channels.length) {
          navigate(`/social-sets/${socialSet?.id}/channels`);
          return;
        }

        setEditingPost(editingPost);
        setCloningPost(cloningPost);
        setChannelIds(channelIds);
        setScheduledAt(scheduledAt);

        if (!isMd) {
          intercomService.hideLauncher();
        }

        setIsOpen(true);
      },
      closeDialog: () => {
        intercomService.showLauncher();
        setIsOpen(false);
      },
    }));

    const handleOnClose = async () => {
      discardChangesDialogRef.current.openDialog();
    };

    const onConfirmDiscardChanges = (): void => {
      setIsOpen(false);
      intercomService.showLauncher();
      onClosed && onClosed();
    };

    const handleOnPostSubmitted = (posts: Post[]): void => {
      setIsOpen(false);
      onPostSubmitted && onPostSubmitted(posts);
    };

    return (
      <>
        <Transition as={Fragment} show={isOpen}>
          <Dialog
            as="div"
            className="relative z-50"
            initialFocus={cancelButtonRef}
            onClose={handleOnClose}
          >
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div>
                <div className="fixed inset-0 bg-gray-900/80 z-50 transition-opacity"></div>
              </div>
            </TransitionChild>

            <div className="fixed inset-0 flex w-screen items-center justify-center z-50">
              <div className="relative w-screen min-[1108px]:w-auto">
                <DialogPanel className="relative transform overflow-hidden w-auto">
                  <CreatePost
                    onPostSubmitted={handleOnPostSubmitted}
                    post={editingPost}
                    clonedPost={cloningPost}
                    dateTime={scheduledAt}
                    preselectedChannelIds={channelIds}
                    onClose={handleOnClose}
                  />
                </DialogPanel>

                {/* Close button on large screens */}
                <div
                  onClick={handleOnClose}
                  className="absolute -top-2 -right-2 hidden lg:block bg-white rounded-full ring-2 ring-white/30 hover:ring-4 transition-all shadow p-1 z-60 cursor-pointer"
                >
                  <XMarkIcon className="w-4 h-4 text-gray-800 shadow button-shadow hover:text-600 transition" />
                </div>

                {/* Close button on small screens */}
                <div
                  onClick={handleOnClose}
                  className="group absolute top-4 right-4 block lg:hidden bg-white rounded-full shadow hover:shadow-md hover:ring-1 ring-gray-400/30 transition-all p-1 z-60 cursor-pointer"
                >
                  <XMarkIcon className="w-4 h-4 text-gray-800 group-hover:text-gray-500 transition-all" />
                </div>
              </div>
            </div>
          </Dialog>
        </Transition>

        <DeleteDialog
          title="Discard Changes?"
          message="You'll lose all your changes, this can't be undone."
          confirmLabel="Discard Changes"
          cancelLabel="Keep Editing"
          ref={discardChangesDialogRef}
          onConfirm={onConfirmDiscardChanges}
        />
      </>
    );
  }
);

export default CreatePostDialog;
