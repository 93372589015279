import { useContext, useEffect } from "react";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import actionTypes from "../../../state/global-state/action-types";
import PaymentMethods from "./components/PaymentMethods";
import useAuthorizedAccess from "../../../hooks/useAuthorizedAccess";
import Unauthorized from "../../../components/common/403";

export default function PaymentMethodsSettings() {
  const { dispatch } = useContext(GlobalStateContext);
  const isAuthorized = useAuthorizedAccess(["billing_write"], [], []);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SETTINGS_BREADCRUMBS,
      payload: [
        {
          name: "Account Settings",
          to: "/settings",
        },
        {
          name: "Payment Methods",
          to: "/settings/payment-methods",
        },
      ],
    });
  }, [dispatch]);

  return (
    <>
      {!isAuthorized && <Unauthorized flat={true} />}

      {isAuthorized && (
        <div className="flex-1 flex flex-col">
          <div className="flex flex-col gap-14">
            <div>
              <PaymentMethods />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
