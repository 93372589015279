import Button from "../../../components/common/Button";
import { Link } from "react-router-dom";
import animation from "../../../assets/animations/affiliate.json";
import Lottie from "react-lottie-player";

export default function Affiliate() {
  return (
    <>
      <div>
        <div className="flex items-center">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Affiliate Program
            </h2>
            <p className="text-sm leading-6 text-gray-500">
              Find out how you can earn money by referring people to Viraly.
            </p>
          </div>
        </div>

        <div className="my-4 border-t border-gray-200"></div>

        <div>
          <div className="relative isolate overflow-hidden">
            <div className="mx-auto max-w-7xl pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-40">
              <div className="px-6 lg:px-0 lg:pt-4">
                <div className="mx-auto max-w-2xl">
                  <div className="max-w-lg">
                    <h1 className="text-xl font-bold tracking-tight text-gray-900">
                      Join our affiliate program
                    </h1>
                    <p className="mt-6 text-sm text-gray-600">
                      Earn money by referring people to Viraly. You will get
                      paid 25% of the revenue for every user you refer. This is
                      a recurring commission, so you will get paid every month
                      as long as the user you referred is still a paying
                      customer.
                    </p>
                    <div className="mt-10 flex items-center gap-x-6">
                      <Button
                        variant="solid"
                        color="brand"
                        text="Become an affiliate"
                        target="_blank"
                        to="https://viraly.getrewardful.com/signup"
                      />

                      <Link
                        to="https://viraly.io/docs/viraly-affiliate-program"
                        target="_blank"
                        className="text-sm font-semibold leading-6 text-gray-900 hover:text-gray-600"
                      >
                        Find out more <span aria-hidden="true">→</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
                <div className="shadow-lg md:rounded-3xl">
                  <div className="bg-secondary-500 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                    <div
                      className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-secondary-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                      aria-hidden="true"
                    />
                    <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none">
                      <Lottie
                        loop
                        animationData={animation}
                        play
                        style={{ height: 300 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
