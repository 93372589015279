const timezonesMap: Record<string, string> = {
  // US aliases
  "US/Eastern": "America/New_York",
  "US/Central": "America/Chicago",
  "US/Mountain": "America/Denver",
  "US/Pacific": "America/Los_Angeles",
  "US/Alaska": "America/Anchorage",
  "US/Hawaii": "Pacific/Honolulu",

  // Legacy numeric zone names (often seen in tzdata)
  EST5EDT: "America/New_York",
  CST6CDT: "America/Chicago",
  MST7MDT: "America/Denver",
  PST8PDT: "America/Los_Angeles",
  HST: "Pacific/Honolulu",

  // Canadian aliases
  "Canada/Atlantic": "America/Halifax",
  "Canada/East-Saskatchewan": "America/Regina",
  "Canada/Mountain": "America/Edmonton",
  "Canada/Newfoundland": "America/St_Johns",

  // European aliases
  GB: "Europe/London",
  "GB-Eire": "Europe/London",

  // Asian aliases
  "Asia/Calcutta": "Asia/Kolkata", // legacy name for Kolkata
  "Asia/Chongqing": "Asia/Shanghai",
  "Asia/Chungking": "Asia/Shanghai",
  "Asia/Harbin": "Asia/Shanghai",
  "Asia/Rangoon": "Asia/Yangon", // alias for Yangon (Myanmar)
  "Asia/Katmandu": "Asia/Kathmandu", // common misspelling
  "Asia/Saigon": "Asia/Ho_Chi_Minh",
  ROC: "Asia/Taipei", // alias for Taipei

  // Australian aliases
  "Australia/ACT": "Australia/Sydney",
  "Australia/Canberra": "Australia/Sydney",

  // Latin American aliases
  "Brazil/East": "America/Sao_Paulo",
  "Brazil/West": "America/Manaus",
  Chile: "Chile/Continental",

  // Mexican aliases
  "Mexico/BajaNorte": "America/Tijuana",
  "Mexico/BajaSur": "America/Hermosillo",

  // Middle Eastern / African aliases
  Egypt: "Africa/Cairo",
  "Africa/Asmera": "Africa/Asmara",

  // Etc and UTC aliases
  "Etc/UCT": "Etc/UTC",
  "Etc/Universal": "Etc/UTC",
  "Etc/Zulu": "Etc/UTC",
  GMT: "Etc/UTC",

  // Russian legacy alias
  "W-SU": "Europe/Moscow",
};

export function normalizeTimezone(timezone: string): string[] {
  const result = [timezone];

  if (timezonesMap[timezone]) {
    result.push(timezonesMap[timezone]);
  }

  return result;
}
