import List from "./components/List";
import useAuthorizedSocialSets from "../../../hooks/useAuthorizedSocialSets";

export default function ListSocialSets() {
  const socialSets = useAuthorizedSocialSets();

  return (
    <>
      <div className="flex flex-col gap-10">
        <div>
          <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-4">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                My Social Sets
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Choose which social set you would like to manage.
              </p>
            </div>
            {/* <div className="ml-4 mt-4 flex-shrink-0">
              <Button
                to="create"
                variant="solid"
                color="brand"
                text="+ Create new social set"
              />
            </div> */}
          </div>
        </div>

        <List socialSets={socialSets} />
      </div>
    </>
  );
}
