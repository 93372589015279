import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import TenantUpdateFormInput from "../../../../../models/form-inputs/tenant-form-input";
import InputField from "../../../../../components/form/InputField";
import Button from "../../../../../components/common/Button";
import { profilePicturePinturaEditorOptions } from "../../../../../data/pintura-editor-options";
import { openDefaultEditor } from "@pqina/pintura";
import { useEffect, useRef, useState } from "react";
import { fileToBase64 } from "../../../../../utils/file-utils";
import UserAvatar from "../../../../../components/common/UserAvatar";
import ValidationMessages from "../../../../../data/validation-messages";

const schema = yup
  .object({
    name: yup
      .string()
      .max(120, ValidationMessages.FullNameLength(120))
      .required(ValidationMessages.FullNameRequired),
    email: yup
      .string()
      .required(ValidationMessages.EmailRequired)
      .email(ValidationMessages.EmailFormat),
    picture: yup.string().notRequired(),
  })
  .required();

export default function EditForm({
  input,
  onSubmit,
  isSubmitting,
}: {
  initialColor?: string;
  input?: TenantUpdateFormInput;
  onSubmit: (input: TenantUpdateFormInput) => void;
  isSubmitting: boolean;
}) {
  const fileRef = useRef(null);
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setImageUrl(input.picture);
    }
  }, [file, input?.picture]);

  const form = useForm<TenantUpdateFormInput>({
    resolver: yupResolver(schema),
    defaultValues: input,
  });

  const submitForm = async () => {
    if (file) {
      const base64 = await fileToBase64(file);
      form.setValue("picture", base64);
    }

    form.handleSubmit(onSubmit)();
  };

  const changePicture = () => {
    fileRef?.current.click();
  };

  const onFilesSelected = (e) => {
    const file = e.target.files[0];

    const imageEditorOptions = profilePicturePinturaEditorOptions;
    imageEditorOptions.src = file;

    const editor = openDefaultEditor(imageEditorOptions);

    editor.on("process", ({ dest }) => {
      setFile(dest);
    });
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex-1">
        <div className="max-w-sm">
          <div className="py-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1">
              <div className="sm:col-span-3">
                <div
                  className="inline-block group hover:cursor-pointer gap-1"
                  onClick={changePicture}
                >
                  {/* <div className="block text-sm font-medium leading-5 text-gray-700">Profile Picture</div> */}
                  <div className="group-hover:opacity-80">
                    <UserAvatar picture={imageUrl} size="xl" />
                  </div>

                  <div className="mt-1 block text-sm font-medium leading-5 text-gray-700 group-hover:text-gray-500">
                    Change Picture
                  </div>
                </div>
              </div>

              <div className="fixed -top-96 -left-96 opacity-0">
                <input
                  type="file"
                  ref={fileRef}
                  onChange={onFilesSelected}
                  accept="image/jpeg,image/png"
                />
              </div>

              <div className="sm:col-span-3">
                <InputField label="Name" name="name" formReturn={form} />
              </div>

              <div className="sm:col-span-3">
                <InputField
                  label="Email"
                  name="email"
                  formReturn={form}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="border-t border-gray-200 bg-white pt-5">
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className=""></div>
          <div className="flex-shrink-0 gap-2 flex">
            <Button
              variant="text"
              color="brand"
              className="w-24"
              text="Cancel"
              onClick={() => navigate(-1)}
            />
            <Button
              text="Save"
              variant="solid"
              color="brand"
              className="w-24"
              type="button"
              onClick={submitForm}
              loading={isSubmitting}
            />
          </div>
        </div>
      </div>
    </>
  );
}
