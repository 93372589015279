import { ChannelType } from "../models/entities/post";

export interface HelpLink {
  title: string;
  link: string;
}

const unavailableLink: HelpLink = {
  title: "Why is my social profile marked as unavailable?",
  link: "https://viraly.io/docs/why-is-my-channel-listed-as-unavailable",
};

const ChannelHelpLinks: Record<ChannelType, HelpLink[]> = {
  Facebook: [
    {
      title: "Troubleshooting Facebook connections",
      link: "https://viraly.io/docs/troubleshooting-facebook-and-instagram-connections",
    },
    {
      title: "My Facebook page is not listed in the available options",
      link: "https://viraly.io/docs/connecting-your-facebook-page-to-viraly#what-to-do-if-page-not-listed",
    },
    {
      title: "How to connect your Facebook page to Viraly",
      link: "https://viraly.io/docs/connecting-your-facebook-page-to-viraly",
    },
    unavailableLink,
  ],
  Instagram: [
    {
      title: "Troubleshooting Instagram connections",
      link: "https://viraly.io/docs/troubleshooting-facebook-and-instagram-connections",
    },
    {
      title: "How to check your Instagram account type",
      link: "https://viraly.io/docs/troubleshooting-facebook-and-instagram-connections#checking-instagram-account-type",
    },
    {
      title:
        "How to convert your Instagram account to a Business or Creator account",
      link: "https://viraly.io/docs/troubleshooting-facebook-and-instagram-connections#convert-instagram-account-type",
    },
    unavailableLink,
  ],
  LinkedIn: [unavailableLink],
  Pinterest: [unavailableLink],
  Threads: [unavailableLink],
  TikTok: [unavailableLink],
  Twitter: [unavailableLink],
  YouTube: [unavailableLink],
  Mastodon: [unavailableLink],
  Bluesky: [unavailableLink],
  GoogleBusiness: [unavailableLink],
};

export default ChannelHelpLinks;
