import { Outlet } from "react-router-dom";
import { canAccessSocialSet } from "../../../utils/auth-utils";
import Unauthorized from "../../../components/common/403";
import NotFound from "../../../components/common/404";
import useCurrentTenant from "../../../hooks/useCurrentTenant";
import useCurrentSocialSet from "../../../hooks/useCurrentSocialSet";
import SchedulerListLayout from "./SchedulerListLayout";
import useCurrentChannel from "../../../hooks/useCurrentChannel";

export default function SchedulerList() {
  const channel = useCurrentChannel();
  const tenant = useCurrentTenant();
  const socialSet = useCurrentSocialSet();

  let content = null;

  if (socialSet) {
    content = canAccessSocialSet(tenant, socialSet.id) ? (
      <SchedulerListLayout channel={channel} socialSet={socialSet}>
        <Outlet />
      </SchedulerListLayout>
    ) : (
      <Unauthorized />
    );
  } else {
    content = <NotFound />;
  }

  return (
    <>
      <div className="flex flex-col gap-5 mx-auto w-full">
        <div className="flex flex-col">{content}</div>
      </div>
    </>
  );
}
