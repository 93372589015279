import { Outlet } from "react-router-dom";
import { useContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import GlobalStateContext from "../../state/global-state/GlobalStateContext";
import socialSetsService from "../../services/api/social-sets-service";
import queryNames from "../../queries/query-names";
import SessionService from "../../services/application/session-service";
import actionTypes from "../../state/global-state/action-types";
import tenantService from "../../services/api/tenant-service";
import { Tooltip } from "react-tooltip";
import "react-toastify/dist/ReactToastify.css";

export default function MainLayout() {
  const { state, dispatch } = useContext(GlobalStateContext);

  const { data: queriedSocialSets } = useQuery({
    queryKey: [queryNames.socialSets],
    queryFn: socialSetsService.list,
    refetchOnMount: false,
  });

  const { data: queriedTenants } = useQuery({
    queryKey: [queryNames.tenants],
    queryFn: tenantService.list,
    refetchOnMount: false,
  });

  // Set tenants list
  useEffect(() => {
    dispatch({ type: actionTypes.SET_TENANTS, payload: queriedTenants });
  }, [queriedTenants]);

  // Set default tenant
  useEffect(() => {
    const check = () => {
      const current =
        SessionService.getTenant(state.tenants) ?? state.tenants[0];

      dispatch({ type: actionTypes.SET_CURRENT_TENANT, payload: current });
    };

    check();
  }, [dispatch, state.currentTenant, state.tenants]);

  // Load social sets and set default one
  useEffect(() => {
    const check = () => {
      dispatch({
        type: actionTypes.SET_SOCIAL_SETS,
        payload: queriedSocialSets,
      });

      const current =
        SessionService.getSocialSet(queriedSocialSets) ??
        queriedSocialSets?.[0];

      dispatch({ type: actionTypes.SET_CURRENT_SOCIAL_SET, payload: current });
    };

    check();
  }, [dispatch, queriedSocialSets]);

  return (
    <>
      <div>
        <div>
          <Outlet />
        </div>
      </div>

      <Tooltip id="tooltip-placeholder" className="viraly-tooltip" />
    </>
  );
}
