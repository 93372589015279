import {
  UserCircleIcon,
  CreditCardIcon,
  UsersIcon,
  BellIcon,
  BuildingOfficeIcon,
  GiftIcon,
  CheckBadgeIcon,
  DocumentCheckIcon,
} from "@heroicons/react/24/outline";

import clsx from "clsx";
import { NavLink, Outlet } from "react-router-dom";
import Breadcrumbs from "../../components/common/BreadCrumbs";
import { useContext } from "react";
import GlobalStateContext from "../../state/global-state/GlobalStateContext";
import { PermissionCode } from "../../services/application/permissions-service";
import { authorizeScope } from "../../utils/auth-utils";
import { UserRole } from "../../models/entities/user";
import { PlanTier } from "../../models/stripe/subscription";
import { usePrefetchQuery } from "@tanstack/react-query";
import queryNames from "../../queries/query-names";
import stripeService from "../../services/api/stripe-service";

const secondaryNavigation: {
  name: string;
  href: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  permissions: PermissionCode[];
  roles: UserRole[];
  tiers: PlanTier[];
}[] = [
  {
    name: "Profile",
    href: "profile",
    icon: UserCircleIcon,
    permissions: [],
    roles: [],
    tiers: [],
  },
  {
    name: "Subscription",
    href: "subscription",
    icon: CheckBadgeIcon,
    permissions: ["billing_write"],
    roles: [],
    tiers: [],
  },
  {
    name: "Payment Methods",
    href: "payment-methods",
    icon: CreditCardIcon,
    permissions: ["billing_write"],
    roles: [],
    tiers: [],
  },
  {
    name: "Invoice History",
    href: "invoices",
    icon: DocumentCheckIcon,
    permissions: ["billing_write"],
    roles: [],
    tiers: [],
  },
  {
    name: "My Workspace",
    href: "workspace",
    icon: BuildingOfficeIcon,
    permissions: [],
    roles: ["Owner"],
    tiers: [],
  },
  {
    name: "Team Members",
    href: "team",
    icon: UsersIcon,
    permissions: ["users_write"],
    roles: [],
    tiers: [],
  },
  {
    name: "Notifications",
    href: "notifications",
    icon: BellIcon,
    permissions: [],
    roles: [],
    tiers: [],
  },
  {
    name: "Affiliate Program",
    href: "affiliate",
    icon: GiftIcon,
    permissions: [],
    roles: [],
    tiers: [],
  },
];

export default function Settings() {
  const { state } = useContext(GlobalStateContext);
  const breadcrumbs = state.settingsBreadcrumbs;

  usePrefetchQuery({
    queryKey: [queryNames.stripeProducts],
    queryFn: stripeService.listProducts,
  });

  usePrefetchQuery({
    queryKey: [queryNames.stripePaymentMethods],
    queryFn: stripeService.getPaymentMethods,
  });

  usePrefetchQuery({
    queryKey: [queryNames.stripePrices],
    queryFn: stripeService.listPrices,
  });

  return (
    <div className="flex flex-col gap-5 mx-auto w-full">
      <Breadcrumbs pages={breadcrumbs} />

      <div className="w-full mx-auto p-4 lg:p-8 xl:flex lg:gap-x-16 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg bg-white">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 xl:block pb-4 xl:flex-none xl:border-0">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul
              role="list"
              className="flex gap-x-3 gap-y-1 whitespace-nowrap xl:flex-col"
            >
              {secondaryNavigation
                .filter((x) =>
                  authorizeScope(
                    x.permissions,
                    x.roles,
                    x.tiers,
                    state.currentTenant
                  )
                )
                .map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        clsx(
                          isActive
                            ? "bg-gray-50 text-primary-600"
                            : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                          "group flex gap-x-3 rounded-md p-2 pr-4 text-sm leading-6 font-semibold"
                        )
                      }
                    >
                      <item.icon className="h-6 w-6 shrink-0" />
                      {item.name}
                    </NavLink>
                  </li>
                ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 sm:px-6 xl:flex-auto xl:px-0 pt-12 xl:py-0">
          <div className="flex flex-col mx-auto xl:mx-0 xl:max-w-none lg:min-h-80 min-h-60">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}
