import React, { useState, useEffect } from "react";
import Spinner from "../common/Spinner";
import clsx from "clsx";

export default function LazyLoadingSkeleton() {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true);
    }, 350);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full bg-white shadow rounded-lg p-4 min-h-[50dvh] pointer-events-none">
      <div
        className={clsx(
          "flex flex-col justify-center items-center gap-4 transition-all h-full",
          showSpinner ? "opacity-100" : "opacity-0"
        )}
      >
        <Spinner size="8" />
        <p className="text-sm text-gray-500">Loading page...</p>
      </div>
    </div>
  );
}
