import { useEffect, useRef } from "react";
import useSubscription from "../../../../hooks/useSubscription";
import PastDueBanner from "./PastDueBanner";
import SubscriptionCanceledBanner from "./SubscriptionCanceledBanner";
import TrialBannerTop from "./TrialBannerTop";

export default function SubscriptionBanners({ onHeightChange }) {
  const { subscription, isTrialing, isPastDue, isCanceled } = useSubscription();
  const bannerRef = useRef(null);

  useEffect(() => {
    if (!bannerRef.current) return;

    // Create a ResizeObserver to listen for height changes
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        // Call the parent callback with the updated height
        if (onHeightChange) {
          onHeightChange(entry.contentRect.height);
        }
      }
    });
    resizeObserver.observe(bannerRef.current);

    // Cleanup the observer on unmount or if dependencies change
    return () => {
      resizeObserver.disconnect();
    };
  }, [onHeightChange]);

  return (
    <div ref={bannerRef} className="fixed top-0 left-0 right-0 z-30">
      {isTrialing && <TrialBannerTop subscription={subscription} />}
      {isPastDue && <PastDueBanner subscription={subscription} />}
      {isCanceled && <SubscriptionCanceledBanner subscription={subscription} />}
    </div>
  );
}
