import Button from "../common/Button";
import animation from "../../assets/animations/astronaut2.json";
import Lottie from "react-lottie-player";
import intercomService from "../../services/application/intercom-service";

export interface InnerErrorProps {
  title?: string;
  message?: string;
  reset?: () => void;
}

export default function InnerError({
  title = "Oops...it looks like we weren't able to load the page",
  message = "If you're using a VPN or AdBlocker, please try disabling them and reloading the page. If the problem continues to persist, please contact support and we will help resolve it ASAP. Thank you!",
  reset,
}: InnerErrorProps) {
  const contactSupport = () => {
    intercomService.openNewMessage();
  };

  return (
    <div className="flex flex-col bg-white rounded-lg p-8 w-full">
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="flex flex-col items-center relative overflow-hidden">
          {animation && (
            <Lottie
              loop
              animationData={animation}
              play
              style={{ height: 200 }}
            />
          )}
        </div>

        <div className="flex flex-col items-center gap-2 text-center mt-4">
          <div className="text-xl font-bold text-gray-900">{title}</div>
          <div className="text-lg text-gray-600 max-w-[680px]">{message}</div>

          <div className="mt-8 flex items-center gap-4">
            <Button
              variant="outline"
              text="Contact Support"
              onClick={contactSupport}
            />

            {reset && (
              <Button variant="solid" text="Reload Page" onClick={reset} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
