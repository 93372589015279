import { useEffect } from "react";
import AttachmentSlot from "../../models/attachment-slot";
import { PostInstance } from "../../models/post-instance";

interface InstagramSyncerProps {
  attachments: AttachmentSlot[];
  postInstance: PostInstance;
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
}

export default function InstagramSyncer({
  attachments,
  postInstance,
  onPostInstanceChanged,
}: InstagramSyncerProps) {
  const instagramConfig = postInstance.postConfig.instagram;

  useEffect(() => {
    const setSystemPostType = () => {
      let systemPostType = instagramConfig.contentOptions.systemPostType;

      const initialSystemPostType = systemPostType;

      if (instagramConfig.contentOptions.postType == "Post") {
        if (attachments.length == 1) {
          if (attachments[0].isPhoto) {
            systemPostType = "ImagePost";
          } else {
            systemPostType = "Reel";
          }
        } else if (attachments.length > 1) {
          systemPostType = "Carousel";
        }
      } else if (instagramConfig.contentOptions.postType == "Reel") {
        systemPostType = "Reel";
      } else if (instagramConfig.contentOptions.postType == "Story") {
        if (attachments.length > 0) {
          if (attachments[0].isPhoto) {
            systemPostType = "PhotoStory";
          } else {
            systemPostType = "VideoStory";
          }
        } else {
          systemPostType = "PhotoStory";
        }
      }

      // const newPostType =
      //   systemPostType == "Reel"
      //     ? "Reel"
      //     : instagramConfig.contentOptions.postType;

      if (systemPostType != initialSystemPostType) {
        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            instagram: {
              ...postInstance.postConfig.instagram,
              contentOptions: {
                ...instagramConfig.contentOptions,
                systemPostType: systemPostType,
                // postType: newPostType,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    setSystemPostType();
  }, [instagramConfig.contentOptions, attachments, postInstance]);

  // useEffect(() => {
  //   const setSystemPostType = () => {
  //     const systemPostType = instagramConfig.contentOptions.systemPostType;

  //     if (
  //       attachments.length == 1 &&
  //       attachments[0].isVideo &&
  //       systemPostType != "Reel"
  //     ) {
  //       const updatedPostInstance: PostInstance = {
  //         ...postInstance,
  //         postConfig: {
  //           ...postInstance.postConfig,
  //           instagram: {
  //             ...postInstance.postConfig.instagram,
  //             contentOptions: {
  //               ...instagramConfig.contentOptions,
  //               systemPostType: "Reel",
  //               postType: "Reel",
  //             },
  //           },
  //         },
  //       };

  //       onPostInstanceChanged(updatedPostInstance, false);
  //     }
  //   };

  //   setSystemPostType();
  // }, [attachments.length]);

  return null;
}
