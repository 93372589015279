import { useNavigate } from "react-router-dom";
import Button from "../../../../components/common/Button";
import planQuotaService from "../../../../services/application/plan-quota-service";
import { useContext } from "react";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import intercomService from "../../../../services/application/intercom-service";

export interface ChannelsPlanProps {}

export default function ChannelsPlan() {
  const navigate = useNavigate();
  const { state } = useContext(GlobalStateContext);

  const subscription = state.currentTenant.subscription;
  const channelsQuota = planQuotaService.getChannelsLimit(subscription);
  const planTier = planQuotaService.getTier(subscription);
  const planName = subscription?.items?.[0]?.plan?.product.name;

  return (
    <>
      <div className="rounded bg-white ">
        <div className="flex flex-col items-end sm:flex-row sm:items-center justify-between gap-4">
          <div className="flex-1 flex flex-col gap-2">
            <div className="text-sm font-semibold text-gray-900">
              Your current plan limits
            </div>

            <p className="text-sm text-gray-900">
              {planTier == "trial" && (
                <span>
                  You're currently on a <b>Free Trial</b> Plan and you can
                  connect up to {channelsQuota} social profiles. To add
                  additional social profiles please activate your subscription.
                </span>
              )}
              {planTier == "high" && (
                <span>
                  You're currently on the <b>{planName}</b> Plan and you can
                  connect up to {channelsQuota} social profiles. To add
                  additional social profiles please contact us to request a
                  customized plan.
                </span>
              )}
              {(planTier == "low" || planTier == "medium") && (
                <span>
                  You're currently on the <b>{planName}</b> Plan and you can
                  connect up to {channelsQuota} social profiles. To add
                  additional social profiles please upgrade your plan.
                </span>
              )}
            </p>
          </div>

          {planTier == "high" && (
            <>
              <Button
                variant="outline"
                color="brand"
                text="Contact us"
                onClick={() => intercomService.openChat()}
                target="_blank"
              />
            </>
          )}

          {planTier != "high" && (
            <>
              <Button
                variant="outline"
                color="brand"
                text={
                  planTier == "trial" ? "Activate Subscription" : "Upgrade Plan"
                }
                onClick={() => navigate("/settings/subscription")}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
