import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

export default function InstagramInfoDialog() {
  return (
    <div className="text-sm text-gray-800 font-light">
      <h1 className="font-semibold">
        Why do I have to authenticate with Facebook?
      </h1>
      <p className="mt-2">
        Facebook requires{" "}
        <span className="font-medium">
          Instagram Business or Creator accounts to be connected to a Facebook
          Page.
        </span>{" "}
        To ensure Viraly functions properly, we need to authenticate your
        account through Facebook.
      </p>

      <h1 className="font-semibold mt-4">What should I do next?</h1>
      <p className="mt-2">
        After you're redirected now,{" "}
        <span className="font-medium">
          log in to the Facebook profile with Admin access to the Facebook Page
        </span>{" "}
        connected with your Instagram account.
      </p>

      <div className="mt-4 flex items-center gap-1 flex-wrap">
        For further details, take a look at our{" "}
        <a
          className="text-primary-600 font-medium"
          href="https://viraly.io/docs/connecting-your-instagram-business-or-creator-account-to-viraly"
          target="_blank"
        >
          <div className="flex gap-1 items-center">
            <ArrowTopRightOnSquareIcon className="w-[10px] h-[10px]" />{" "}
            Instagram Connection Guide.
          </div>
        </a>
      </div>
    </div>
  );
}
