/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import AuthService from "../auth/auth-service";
import errorReporter from "../../utils/error-reporter";
import sessionService from "../application/session-service";
import { Hub } from "aws-amplify/utils";

const apiBaseURL: string = import.meta.env.VITE_API_BASE_URL;

declare module "axios" {
  interface AxiosRequestConfig {
    disableGlobalErrorReporting?: boolean;
  }
}

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: apiBaseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor for JWT token injection
axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<any> => {
    const { idToken } = await AuthService.getTokens();

    if (!idToken) {
      const controller = new AbortController();
      controller.abort();

      Hub.dispatch("reauth", null);

      return {
        ...config,
        signal: controller.signal,
      };
    }

    const tenantId = sessionService.currentTenantId();

    if (tenantId) {
      config.headers = {
        ...config.headers,
        "x-viraly-tenant-id": tenantId.toString(),
      };
    }

    if (idToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${idToken}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for global error handling
axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  (error) => {
    // Global error handling logic
    if (error.name == "CanceledError") {
      return; // Do nothing if request was cancelled
    }
    const disableGlobalErrorReporting =
      error?.config?.disableGlobalErrorReporting;

    if (!disableGlobalErrorReporting) {
      errorReporter.alertErrors(error);
    }

    console.error("Something went wrong:", error.response);
    return Promise.reject(error);
  }
);

class ApiService {
  static async get<T = any>(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const response = await axiosInstance.get<T>(url, config);
    return response.data;
  }

  static async post<T = any>(
    url: string,
    data: object,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const response = await axiosInstance.post<T>(url, data, config);
    return response.data;
  }

  static async put<T = any>(
    url: string,
    data: object,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const response = await axiosInstance.put<T>(url, data, config);
    return response.data;
  }

  static async delete<T = any>(
    url: string,
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    const response = await axiosInstance.delete<T>(url, config);
    return response.data;
  }
}

export default ApiService;
