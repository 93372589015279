import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import Channel from "../../../../models/entities/channel";
import { Link } from "react-router-dom";
import {
  getChannelImageUrl,
  getChannelTypeImageUrl,
} from "../../../../data/channels-config";
import { RectangleStackIcon } from "@heroicons/react/24/outline";
import Post from "../../../../models/entities/post";

interface FilterChannelsSelectorProps {
  unfilteredPosts: Post[];
  channels: Channel[];
  selected: Channel[];
  onSelected: (selection: Channel[]) => void;
}

export default function FilterChannelsSelector({
  unfilteredPosts,
  channels,
  selected,
  onSelected,
}: FilterChannelsSelectorProps) {
  const toggleChannel = (channel: Channel) => {
    let updated = [];

    if (selected.findIndex((x) => x.id == channel.id) != -1) {
      updated = selected.filter((c) => c.id != channel.id);
    } else {
      updated = [...selected];
      updated.push(channel);
    }

    onSelected(updated);
  };

  return (
    <Menu as="div" className="flex relative text-left">
      {({ open }) => (
        <>
          <MenuButton
            className={clsx(
              "flex w-full justify-center items-center gap-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-inset",
              selected.length > 0
                ? "ring-2 ring-primary-400 text-primary-600 hover:bg-primary-50"
                : "ring-1 ring-gray-200 hover:bg-gray-50"
            )}
          >
            <ul role="list" className="flex items-center gap-2">
              <RectangleStackIcon className="w-4 h-4" />
              <div className="font-normal">Social Profiles</div>
              {/* {selected.length > 0 && (
                <span className="inline-flex items-center rounded-full bg-primary-50 px-2 py-1 text-xs font-medium text-primary-600 ring-1 ring-inset ring-primary-500/10">
                  {selected.length}
                </span>
              )} */}
            </ul>

            {open ? (
              <ChevronUpIcon className="h-5 w-5 " />
            ) : (
              <ChevronDownIcon className="h-5 w-5 " />
            )}
          </MenuButton>

          <MenuItems
            transition
            anchor="bottom end"
            className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-72 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div className="py-1 px-1">
              {channels.map((channel) => (
                <div key={channel.id}>
                  {
                    <li
                      onClick={() => toggleChannel(channel)}
                      className={clsx(
                        "group text-sm flex items-center justify-between py-2 px-2 rounded-md hover:bg-gray-50 cursor-pointer"
                      )}
                    >
                      <div className="flex-1 flex items-center min-w-0 gap-x-4">
                        <div className="relative">
                          <img
                            referrerPolicy="no-referrer"
                            className="h-8 w-8 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                            src={getChannelImageUrl(channel)}
                          />
                          <img
                            className="h-5 w-5 flex-none rounded-full border border-white bg-gray-50 absolute top-3.5 right-0 -mr-2"
                            src={getChannelTypeImageUrl(channel)}
                          />
                        </div>
                        <div className="flex items-center gap-2 max-w-[190px] overflow-hidden whitespace-nowrap flex-1 text-sm leading-0 text-gray-800 text-ellipsis">
                          <span className="flex-1 truncate">
                            {channel.name}
                          </span>

                          <span className="ml-auto inline-flex items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                            {
                              (unfilteredPosts ?? []).filter(
                                (c) => c.channel.id == channel.id
                              ).length
                            }
                          </span>
                        </div>
                        <div className="ml-auto">
                          <input
                            type="checkbox"
                            readOnly={true}
                            checked={
                              selected.findIndex((x) => x.id == channel.id) !=
                              -1
                            }
                            className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-600 focus:ring-transparent cursor-pointer"
                          />
                        </div>
                      </div>
                    </li>
                  }
                </div>
              ))}

              {!channels.length ? (
                <div>
                  <Link
                    to="/social-sets"
                    className="group text-sm flex items-center justify-between py-2 px-2 rounded-md hover:bg-gray-50 cursor-pointer"
                  >
                    <div className="flex-1 flex items-center min-w-0 gap-x-4">
                      <div className="flex flex-col gap-2 font-medium text-sm text-slate-600">
                        No social profiles found in the current social set.
                        Please connect some profiles first or change your
                        current social set.{" "}
                        <span className="font-semibold">Connect now</span>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null}
            </div>
          </MenuItems>
        </>
      )}
    </Menu>
  );
}
