import clsx from "clsx";
import { NavLink, Outlet } from "react-router-dom";
import SocialSet from "../../models/entities/social-set";
import useAuthorizedAccess from "../../hooks/useAuthorizedAccess";
import Unauthorized from "../../components/common/403";
import useCurrentSocialSet from "../../hooks/useCurrentSocialSet";
import { FolderPlus, Folders, Plus, Megaphone, Settings } from "lucide-react";

function getNavigation(currentSocialSet: SocialSet): {
  name: string;
  href: string;
  icon: any;
}[] {
  return [
    {
      name: "New Social Profile",
      href: `/social-sets/${currentSocialSet.id}/channels`,
      icon: Plus,
    },
    {
      name: "My Social Profiles",
      href: `/social-sets/${currentSocialSet.id}`,
      icon: Megaphone,
    },
    {
      name: "New Social Set",
      href: `/social-sets/create`,
      icon: FolderPlus,
    },
    {
      name: `Current Social Set Settings`,
      href: `/social-sets/${currentSocialSet.id}/edit`,
      icon: Settings,
    },
    {
      name: "My Social Sets",
      href: `/social-sets`,
      icon: Folders,
    },
  ];
}

export default function SocialSetsRoot() {
  const isAuthorized = useAuthorizedAccess(["social_sets_write"], [], []);
  const currentSocialSet = useCurrentSocialSet();

  const navigation = getNavigation(currentSocialSet);

  return (
    <>
      {!isAuthorized && <Unauthorized flat={true} />}
      {isAuthorized && (
        <div className="flex flex-col gap-5 mx-auto w-full">
          <div className="w-full p-4 lg:p-8 xl:flex lg:gap-x-16 shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg bg-white">
            <aside className="flex overflow-x-auto border-b border-gray-900/5 xl:block pb-4 xl:flex-none xl:border-0">
              <nav className="flex-none px-4 sm:px-6 lg:px-0">
                <ul
                  role="list"
                  className="flex gap-x-3 gap-y-1 whitespace-nowrap xl:flex-col"
                >
                  {(() => {
                    const items = [];
                    navigation.forEach((item, index) => {
                      // Check if the current item is "New Social Set" and the previous one is "My Social Profiles"
                      if (
                        index > 0 &&
                        item.name === "New Social Set" &&
                        navigation[index - 1].name === "My Social Profiles"
                      ) {
                        items.push(
                          <li key="divider" className="py-2">
                            <div className="border-t border-gray-200" />
                          </li>
                        );
                      }
                      items.push(
                        <li key={item.name}>
                          <NavLink
                            end={true}
                            to={item.href}
                            className={({ isActive }) =>
                              clsx(
                                isActive
                                  ? "bg-gray-50 text-primary-600"
                                  : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                                "group flex gap-x-3 rounded-md p-2 pr-4 text-sm leading-6 font-semibold"
                              )
                            }
                          >
                            <item.icon className="h-6 w-6 shrink-0" />
                            {item.name}
                          </NavLink>
                        </li>
                      );
                    });
                    return items;
                  })()}
                </ul>
              </nav>
            </aside>

            <main className="px-4 sm:px-6 xl:flex-auto xl:px-0 pt-12 xl:py-0">
              <div className="flex flex-col lg:min-h-80 min-h-60 w-full max-w-screen-md mx-auto">
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      )}
    </>
  );
}
