import { ErrorBoundary } from "@sentry/react";
import { Outlet } from "react-router-dom";
import InnerError from "../errors/InnerError";
import { ReactNode } from "react";

interface OutletErrorBoundaryProps {
  title?: string;
  message?: string;
  children?: ReactNode;
}

export default function OutletErrorBoundary({
  title,
  message,
  children,
}: OutletErrorBoundaryProps) {
  return (
    <ErrorBoundary
      fallback={({ resetError }) => (
        <InnerError reset={resetError} title={title} message={message} />
      )}
    >
      {children ? children : <Outlet />}
    </ErrorBoundary>
  );
}
