import SessionService from "../../services/application/session-service";
import actionTypes from "./action-types";
import { GlobalStateModel } from "./models";

export const initialState: GlobalStateModel = {
  socialSets: null,
  currentSocialSet: null,
  tenants: null,
  currentTenant: null,
  currentChanel: null,
  settingsBreadcrumbs: [
    {
      name: "Account Settings",
      to: "/settings",
    },
  ],
};

function reducer(state: GlobalStateModel, action) {
  switch (action.type) {
    case actionTypes.SET_CURRENT_SOCIAL_SET:
      SessionService.setSocialSet(action.payload);
      return { ...state, currentSocialSet: action.payload };

    case actionTypes.SET_SOCIAL_SETS:
      return { ...state, socialSets: action.payload };

    case actionTypes.SET_CURRENT_TENANT:
      SessionService.setTenant(action.payload);

      return { ...state, currentTenant: action.payload };

    case actionTypes.SET_TENANTS:
      return { ...state, tenants: action.payload };

    case actionTypes.SET_SETTINGS_BREADCRUMBS:
      return { ...state, settingsBreadcrumbs: action.payload };

    case actionTypes.SET_CURRENT_CHANNEL:
      return { ...state, currentChanel: action.payload };

    default:
      return state;
  }
}

export default reducer;
