import { useMemo } from "react";
import useCurrentTenant from "./useCurrentTenant";

const useSubscription = () => {
  const currentTenant = useCurrentTenant();
  const subscription = currentTenant?.subscription;

  const isTrialing = useMemo(
    () => subscription?.status === "trialing",
    [subscription?.status]
  );
  const isPastDue = useMemo(
    () => subscription?.status === "past_due",
    [subscription?.status]
  );
  const isCanceled = useMemo(
    () => subscription?.status === "canceled",
    [subscription?.status]
  );

  return { subscription, isTrialing, isPastDue, isCanceled };
};

export default useSubscription;
