import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import SubscriptionBanners from "./components/banners/SubscriptionBanners";
import useSubscription from "../../hooks/useSubscription";
import { useState } from "react";

export default function MainLayoutWithoutSidebar() {
  const { isTrialing, isPastDue, isCanceled } = useSubscription();
  const showSubscriptionBanner = isTrialing || isPastDue || isCanceled;
  const [bannerHeight, setBannerHeight] = useState(0);

  return (
    <>
      <div>
        <div>
          {showSubscriptionBanner && (
            <SubscriptionBanners onHeightChange={setBannerHeight} />
          )}
          {/* Apply dynamic padding to account for the banner's height */}
          <main
            style={{
              paddingTop: showSubscriptionBanner
                ? `${bannerHeight}px`
                : undefined,
            }}
          >
            <div className="px-4 py-4 sm:px-6 lg:py-5">
              {/* Offset the header by the banner height */}
              <div
                className="sticky z-20 flex"
                style={{
                  top: showSubscriptionBanner ? `${bannerHeight}px` : 0,
                }}
              >
                <Header hasSidebar={false} />
              </div>
              <div className="mt-5">
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
