import { RocketLaunchIcon } from "@heroicons/react/24/outline";
import ChannelConfig from "../models/channel-config";
import { ReactNode } from "react";

export default function ConnectChannelCard({
  channelConfig,
  children,
}: {
  channelConfig: ChannelConfig;
  children?: ReactNode | ((canConnect) => ReactNode);
}) {
  return (
    <li
      key={channelConfig.name}
      className="px-6 py-4 flex items-center justify-between hover:bg-gray-50 transition-colors"
    >
      <div className="flex items-center space-x-4">
        <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
          {/* <Facebook className="w-6 h-6 text-blue-600" /> */}

          <img
            className="h-12 w-12 flex-none rounded-full bg-gray-50"
            src={channelConfig.imageUrl}
            alt=""
          />
        </div>
        <div>
          <h3 className="flex items-center gap-3 text-lg font-medium text-gray-900">
            {channelConfig.name}

            {channelConfig.tags.includes("ComingSoon") && (
              <span className="inline-flex items-center gap-x-1.5 rounded-full bg-orange-100 px-2 py-0.5 text-xs font-medium text-orange-700">
                <RocketLaunchIcon className="w-3.5 text-red-400" />
                Coming soon
              </span>
            )}
          </h3>
          <p className="text-sm text-gray-500">{channelConfig.description}</p>
        </div>
      </div>
      <>
        {typeof children === "function"
          ? children(!channelConfig.tags.includes("ComingSoon"))
          : children}
      </>
    </li>

    // <li

    //   className="flex items-center justify-between p-4 sm:p-5 border border-gray-200 rounded-lg"
    // >
    //   <div className="flex items-center min-w-0 gap-x-4">

    //     <div className="min-w-0 flex-auto">
    //       <div className="flex items-center gap-3">
    //         <p className="text-md font-semibold leading-6 text-gray-900">

    //         </p>
    //         {channelConfig.tags.includes("ComingSoon") && (
    //           <span className="inline-flex items-center gap-x-1.5 rounded-full bg-orange-100 px-2 py-0.5 text-xs font-medium text-orange-700">
    //             <RocketLaunchIcon className="w-3.5 text-red-400" />
    //             Coming soon
    //           </span>
    //         )}
    //       </div>

    //       <p className="text-left text-sm leading-4 text-gray-500">
    //       </p>
    //     </div>
    //   </div>
    //   <div>
    //     <>
    //       {typeof children === "function"
    //         ? children(!channelConfig.tags.includes("ComingSoon"))
    //         : children}
    //     </>
    //   </div>
    // </li>
  );
}
