import dayjs from "dayjs";
import { useContext, useEffect, useRef, useState } from "react";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import actionTypes from "../../../state/global-state/action-types";
import Button from "../../../components/common/Button";
import UserAvatar from "../../../components/common/UserAvatar";
import DeleteDialog from "../../../components/dialogs/DeleteDialog";
import DeleteDialogContent from "./components/DeleteDialogContent";
import accountService from "../../../services/api/account-service";
import alertService from "../../../services/alert/alert-service";
import { useNavigate } from "react-router-dom";

export default function ProfileSettings() {
  const navigate = useNavigate();
  const { dispatch, state } = useContext(GlobalStateContext);
  const [isValid, setIsValid] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteAccountDialogRef = useRef(null);
  const user = state.currentTenant.user;
  const owners = state.tenants.filter((x) => x.user.role == "Owner");

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SETTINGS_BREADCRUMBS,
      payload: [
        {
          name: "Account Settings",
          to: "/settings",
        },
        {
          name: "Profile",
          to: "/settings/profile",
        },
      ],
    });
  }, [dispatch]);

  const deleteAccountConfirmed = async () => {
    setIsDeleting(true);

    try {
      await accountService.delete();
      alertService.success("Account deleted successfully.");
      navigate("/sign-out");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <div>
        <div className="flex items-center">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Profile
            </h2>
            <p className="text-sm leading-6 text-gray-500">
              Profile settings and information.
            </p>
          </div>

          <div className="ml-auto">
            <Button
              to="edit"
              variant="solid"
              color="brand"
              className="w-24"
              text="Edit"
            />
          </div>
        </div>

        <div className="my-4 border-t border-gray-200"></div>

        <dl className="py-5 mb-5 space-y-6 divide-y divide-gray-100 border-b border-b-gray-100 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Profile picture
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <UserAvatar picture={user.picture} size="lg" />
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Created at
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {dayjs(user.createdAt).format("MMM DD, YYYY, hh:mm a")}
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Name
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">{user.name}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Email address
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">{user.email}</div>
            </dd>
          </div>

          <div className="pt-6 sm:flex">
            <dt className="font-medium text-red-600 sm:w-64 sm:flex-none sm:pr-6">
              Delete account
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 flex flex-col gap-4">
                {owners.length ? (
                  <div className="italic">
                    Your account is currently an owner of the following
                    workspaces:{" "}
                    <span className="font-semibold">
                      {owners.map((x) => x.tenant.name).join(", ")}.
                    </span>
                    <br></br>
                    Deleting your account will delete all the workspaces you
                    own, including all of data associated with them. The delete
                    action is immediate and irreversible. After deletion, you
                    will not be able to recover your account or any of the data
                    associated with it.
                  </div>
                ) : (
                  <div className="italic">
                    Deleting your account will delete all of your data and is
                    irreversible. After deletion, you will not be able to
                    recover your account or any of the data associated with it.{" "}
                    <br></br>
                    Are you sure you want to delete your account?
                  </div>
                )}
                <Button
                  onClick={() => deleteAccountDialogRef.current?.openDialog()}
                  variant="outline"
                  color="red"
                  className="w-40"
                  loading={isDeleting}
                  loadingText="Deleting"
                  text="Delete Account"
                />
              </div>
            </dd>
          </div>
        </dl>
      </div>

      <DeleteDialog
        title="Delete account"
        confirmLabel="Delete"
        ref={deleteAccountDialogRef}
        disabled={!isValid}
        onConfirm={deleteAccountConfirmed}
      >
        <DeleteDialogContent
          owners={owners}
          user={user}
          validationChanged={(isValid) => {
            setIsValid(isValid);
            console.log(isValid);
          }}
        />
      </DeleteDialog>
    </>
  );
}
