import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import userService from "../../../../services/api/user-service";
import UserProfile from "./components/UserProfile";
import queryNames from "../../../../queries/query-names";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import Spinner from "../../../../components/common/Spinner";
import { invalidateQuery } from "../../../../queries/query-utils";
import { useContext, useEffect } from "react";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import actionTypes from "../../../../state/global-state/action-types";
import NotFound from "../../../../components/common/404";

export default function ViewUser() {
  const { id } = useParams();
  const { dispatch } = useContext(GlobalStateContext);
  const query = useQuery({
    queryKey: [queryNames.users],
    queryFn: userService.list,
  });

  const { isLoading, isError, data: users, error } = query;
  const user = users?.find((x) => x.id == id);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SETTINGS_BREADCRUMBS,
      payload: [
        {
          name: "Account Settings",
          to: "/settings",
        },
        {
          name: "Team Members",
          to: "/settings/team",
        },
        {
          name: user?.name ?? (isLoading ? "" : "Not Found"),
          to: `/settings/team/${id}`,
        },
      ],
    });
  }, [dispatch, id, user?.name, isLoading]);

  let content = null;

  if (isLoading) {
    content = <Spinner />;
  } else if (isError) {
    content = (
      <ErrorMessage
        error={error}
        onRetry={() => {
          query.refetch();
        }}
      />
    );
  } else if (user) {
    content = (
      <UserProfile
        user={user}
        onRefetch={() => {
          query.refetch();
          invalidateQuery([queryNames.users]);
        }}
      />
    );
  } else {
    content = <NotFound flat={true} />;
  }

  return (
    <>
      <div className="flex flex-col justify-center min-h-[315px]">
        {content}
      </div>
    </>
  );
}
