import config from "./../amplifyconfiguration.json";

import { Amplify } from "aws-amplify";
import { ConsoleLogger, Hub } from "aws-amplify/utils";
import { createIDBPersister } from "../queries/indexdb-persister";
import { queryClient } from "../queries/query-client";
import intercomService from "../services/application/intercom-service";
import localBrowserStorage from "../services/browser-storage/local-browser-storage";
import sessionBrowserStorage from "../services/browser-storage/session-browser-storage";
import alertService from "../services/alert/alert-service";

const authHostname = import.meta.env.VITE_AUTH_HOSTNAME;
const cognitoRegion = import.meta.env.VITE_COGNITO_REGION;
const cognitoIdentityPoolId = import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID;
const cognitoUserPoolId = import.meta.env.VITE_COGNITO_USER_POOL_ID;
const cognitoUserPoolClientId = import.meta.env
  .VITE_COGNITO_USER_POOL_CLIENT_ID;
const oauthRedirectUrl = `${window.location.origin}/oauth/cognito-redirect/`;

ConsoleLogger.LOG_LEVEL = "WARNING";
window.LOG_LEVEL = "WARNING";

const amplifyConfig = {
  ...config,
  aws_project_region: cognitoRegion,
  aws_cognito_region: cognitoRegion,
  aws_cognito_identity_pool_id: cognitoIdentityPoolId,
  aws_user_pools_id: cognitoUserPoolId,
  aws_user_pools_web_client_id: cognitoUserPoolClientId,
  oauth: {
    ...config.oauth,
    domain: authHostname,
    redirectSignIn: oauthRedirectUrl,
    redirectSignOut: oauthRedirectUrl,
  },
};

Amplify.configure(amplifyConfig);

const clearCaches = () => {
  queryClient.clear();
  createIDBPersister().removeClient();
  localBrowserStorage.clear();
  sessionBrowserStorage.clear();
};

const onSignedOut = () => {
  clearCaches();
  intercomService.shutdown();
};

Hub.listen("reauth", () => {
  console.log("Reauth event has been triggered");
  // onSignedOut();
  alertService.info("Your session has expired. Redirecting you to login...");

  setTimeout(() => {
    window.location.href = "/scheduler";
  }, 2500);
});

Hub.listen("auth", ({ payload }) => {
  console.log("A new auth event has happened: ", payload);

  switch (payload.event) {
    case "signedIn":
      clearCaches();
      break;

    case "signedOut":
      onSignedOut();
      break;

    case "tokenRefresh":
      console.info("Token refresh event: ", payload);
      break;
  }
});
