export default function LinkedInInfoDialog() {
  return (
    <div className="text-sm text-gray-800 font-light">
      <p>
        You can connect multiple{" "}
        <span className="font-medium">
          LinkedIn Pages and personal Profiles
        </span>{" "}
        to Viraly.
      </p>

      <p className="mt-5">
        When connecting LinkedIn profiles, log in to the account which has
        access to the pages you want to connect. Viraly will try to connect to
        the account{" "}
        <span className="font-medium">
          currently logged in on your browser.
        </span>{" "}
      </p>

      <p className="mt-5">
        If you are not currently logged in, you will be prompted to log in. When
        connecting a <span className="font-medium">LinkedIn Page</span>, make
        sure you have the necessary{" "}
        <span className="font-medium">admin permissions</span>.
      </p>
    </div>
  );
}
