import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { ReactNode, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import SocialSet from "../../../models/entities/social-set";
import queryNames from "../../../queries/query-names";
import postQueueService from "../../../services/api/post-queue-service";
import SchedulerViewToggle from "../components/SchedulerViewToggle";
import QueueCounter from "./components/QueueCounter";
import Channel from "../../../models/entities/channel";
import Button from "../../../components/common/Button";
import { OpenComposerParams } from "../../../components/dialogs/CreatePostDialog";
import PostActions from "../../shared/post-actions/PostActions";

const navigation = [
  {
    name: "Scheduled",
    nameSm: "Scheduled",
    href: "scheduled",
    counterKey: "queued",
  },
  { name: "Drafts", nameSm: "Drafts", href: "drafts", counterKey: "drafts" },
  {
    name: "Published",
    nameSm: "Published",
    href: "published",
    counterKey: "published",
  },
  {
    name: "Approvals",
    nameSm: "Approvals",
    href: "approvals",
    counterKey: "approvals",
  },
];

const secondaryNavigation = [
  { name: "My Posts", nameSm: "Posts", href: "posts" },
  { name: "Media Library", nameSm: "Media", href: "media-library" },
  { name: "Hashtag Lists", nameSm: "Hashtags", href: "hashtag-lists" },
];

interface SchedulerListLayoutProps {
  channel?: Channel;
  socialSet: SocialSet;
  children: ReactNode;
}

export default function SchedulerListLayout({
  channel,
  socialSet,
  children,
}: SchedulerListLayoutProps) {
  const postComposerRef = useRef(null);

  const { data: counters } = useQuery({
    queryKey: [queryNames.getSingleQueueCounters, socialSet?.id, channel?.id],
    queryFn: () =>
      postQueueService.getSingleQueueCounters(socialSet.id, channel?.id),
  });

  const openComposer = (params: OpenComposerParams) => {
    postComposerRef.current?.openDialog(params);
  };

  return (
    <>
      <div className="flex flex-col gap-2 xl:flex-row xl:gap-6 w-full">
        {/* Vertical navigation on large screens */}
        <div className="w-[14.2rem] bg-white shadow rounded-2xl p-4 hidden xl:block sticky top-24 lg:h-[calc(100dvh-14rem)] xl:h-[calc(100dvh-8rem)]">
          <ul role="list" className="flex flex-1 flex-col gap-y-2 h-full">
            <li>
              <SchedulerViewToggle />
            </li>

            <li className="border-b -mx-4 my-2"></li>

            <li className="mb-4">
              <Button
                className="w-full text-left"
                variant="solid"
                color="brand"
                text=""
                onClick={() =>
                  openComposer({
                    channelIds: [channel?.id].filter((x) => !!x),
                  })
                }
              >
                <div className="flex-1 flex justify-center">Create Post</div>
              </Button>
            </li>

            <li className="">
              <ul role="list" className="space-y-1 h-full flex flex-col">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        clsx(
                          isActive
                            ? " text-primary-600 bg-gray-50"
                            : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                          "group flex justify-between items-center gap-x-1 rounded-lg p-2 px-4 text-sm leading-6 font-semibold"
                        )
                      }
                    >
                      <span>{item.name}</span>
                      <QueueCounter
                        counterKey={item.counterKey}
                        counters={counters}
                      />
                    </NavLink>
                  </li>
                ))}

                {/* Settings */}
                {/* {channel && (
                  <li className="flex-1 flex flex-col justify-end overflow-hidden">
                    <div className="border-t">
                      <NavLink
                        to="settings"
                        className={({ isActive }) =>
                          clsx(
                            isActive
                              ? " text-primary-600 bg-gray-50"
                              : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                            "group flex justify-start gap-2 items-center py-2 mt-2 rounded-lg px-4 text-sm leading-6 font-semibold"
                          )
                        }
                      >
                        <Cog6ToothIcon className="w-6" />
                        <span>Social Profile Settings</span>
                      </NavLink>
                    </div>
                  </li>
                )} */}
              </ul>
            </li>

            <li className="border-t">
              <ul role="list" className="space-y-1 mt-2">
                {secondaryNavigation.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.href}
                      className={({ isActive }) =>
                        clsx(
                          isActive
                            ? " text-primary-600 bg-gray-50"
                            : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                          "group flex justify-between items-center gap-x-1 rounded-lg p-2 px-4 text-sm leading-6 font-semibold"
                        )
                      }
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>

        {/* Horizontal navigation on small screens */}
        {/* Row 1 */}
        <div className="bg-white shadow rounded-xl p-2 flex xl:hidden overflow-x-auto">
          <ul
            role="list"
            className="flex flex-1 items-center justify-between gap-px"
          >
            <li>
              <SchedulerViewToggle compact={true} />
            </li>
            <li className="bg-white">
              <Button
                className="w-full text-left"
                variant="solid"
                color="brand"
                text="Create Post"
                onClick={() =>
                  openComposer({
                    channelIds: [channel?.id].filter((x) => !!x),
                  })
                }
              >
                <div className="flex-1 flex justify-center">
                  <span className="">Create Post</span>
                </div>
              </Button>
            </li>
          </ul>
        </div>

        {/* Row 2 */}
        <div className="bg-white shadow rounded-xl p-2 flex xl:hidden overflow-x-auto">
          <ul role="list" className="flex items-center justify-center gap-px">
            {secondaryNavigation.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    clsx(
                      isActive
                        ? " text-primary-600 bg-gray-50"
                        : "text-gray-700 hover:text-primary-600 hover:bg-gray-50 ",
                      "group flex gap-2 items-center rounded-lg py-2 px-4 text-sm leading-6 font-semibold"
                    )
                  }
                >
                  {item.nameSm}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        {/* Row 3 */}
        <div className="bg-white shadow rounded-xl p-2 flex xl:hidden overflow-x-auto">
          <ul role="list" className="flex items-center justify-center gap-0.5">
            {navigation.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.href}
                  className={({ isActive }) =>
                    clsx(
                      isActive
                        ? " text-primary-600 bg-gray-50"
                        : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                      "group flex justify-between items-center gap-x-1 rounded-lg py-2 px-4 text-sm leading-6 font-semibold"
                    )
                  }
                >
                  <span>{item.nameSm}</span>
                  {counters && counters[item.counterKey] !== undefined && (
                    <QueueCounter
                      counterKey={item.counterKey}
                      counters={counters}
                    />
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        <div className="flex-1 w-auto mt-3 lg:h-[calc(100dvh-14rem)] xl:h-[calc(100dvh-8rem)]">
          {children}
        </div>
      </div>

      <PostActions composerRef={postComposerRef} />
    </>
  );
}
