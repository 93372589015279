import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ArrowRightStartOnRectangleIcon,
  Bars3Icon,
  ChevronDownIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import SubscriptionPill from "./SubscriptionPill";
import TenantListPicker from "./TenantListPicker";
import DefaultAvatar from "../../../components/common/DefaultAvatar";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import useCurrentTenant from "../../../hooks/useCurrentTenant";
import useTenants from "../../../hooks/useTenants";

const menuNavigation = [
  {
    name: "Account Settings",
    href: "/settings",
    icon: AdjustmentsVerticalIcon,
    current: false,
  },
  {
    name: "Manage Social Sets",
    href: "/social-sets",
    icon: Squares2X2Icon,
  },
];

const tabs = [
  { name: "Post Scheduler", nameSm: "Scheduler", href: "/scheduler" },
  { name: "Analytics", nameSm: "Analytics", href: "/analytics" },
];

export interface HeaderProps {
  hasSidebar: boolean;
  setSidebarOpen?: (open: boolean) => void;
}

export default function Header({ hasSidebar, setSidebarOpen }: HeaderProps) {
  const navigate = useNavigate();

  const currentTenant = useCurrentTenant();
  const tenants = useTenants();

  return (
    <div className="h-full flex-1 flex items-center border-b border-gray-200 bg-white rounded-2xl">
      <div className="w-full">
        <div className="px-4 py-2">
          <div className="flex items-center gap-4 sm:gap-10">
            {hasSidebar && (
              <>
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                  onClick={() => setSidebarOpen?.(true)}
                >
                  <Bars3Icon className="h-6 w-6" />
                </button>
              </>
            )}

            {!hasSidebar && (
              <div className="items-center gap-x-2">
                <Link to="/scheduler">
                  <img
                    src="/logo/viraly-logo-square-dense.png"
                    alt="Viraly Logo"
                    className="h-8 w-auto hidden md:block"
                  />
                  <img
                    src="/logo/viraly-logo-square-dense.png"
                    alt="Viraly Logo"
                    className="h-8 w-auto md:hidden"
                  />
                </Link>
              </div>
            )}

            {/* Navigation items */}
            <div>
              <nav aria-label="Tabs" id="tab1" className="flex space-x-4">
                {tabs.map((tab) => (
                  <NavLink
                    key={tab.name}
                    to={tab.href}
                    className={({ isActive }) =>
                      clsx(
                        isActive
                          ? "bg-gray-100 text-gray-700"
                          : "text-gray-500 hover:text-gray-700 hover:bg-gray-100",
                        "rounded-md px-3 py-2 text-sm font-medium truncate"
                      )
                    }
                  >
                    <span className="hidden sm:block">{tab.name}</span>
                    <span className="block sm:hidden">{tab.nameSm}</span>
                  </NavLink>
                ))}
              </nav>
            </div>

            {/* Dropdown */}
            <div className="flex flex-1 self-stretch">
              <div className="flex flex-1"></div>

              {/* Medium+ screens  */}
              <div className="items-center gap-x-4 lg:gap-x-6 hidden sm:flex">
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  {({ open }) => (
                    <>
                      <MenuButton className="-m-1.5 flex items-center p-1.5 group rounded-xl cursor-pointer">
                        <div className="flex items-center sm:items-start group-hover:opacity-70">
                          <div className="flex flex-col gap-1 ml-4">
                            <div className="text-sm font-medium leading-4 text-gray-900 truncate max-w-56">
                              {currentTenant?.tenant.name}
                            </div>
                            <div className="w-full">
                              <SubscriptionPill
                                subscription={currentTenant?.subscription}
                              />
                            </div>
                          </div>

                          {open ? (
                            <ChevronUpIcon className="ml-3 h-5 w-5 text-gray-400" />
                          ) : (
                            <ChevronDownIcon className="ml-3 h-5 w-5 text-gray-400" />
                          )}
                        </div>
                      </MenuButton>

                      <MenuItems
                        transition
                        anchor="bottom end"
                        className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-20 w-64 rounded-md bg-white py-2 px-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                      >
                        <div className="px-2">
                          <div className="flex items-center gap-2">
                            <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-200">
                              {currentTenant?.user?.picture ? (
                                <img
                                  referrerPolicy="no-referrer"
                                  src={currentTenant.user.picture}
                                />
                              ) : (
                                <DefaultAvatar />
                              )}
                            </span>

                            <div className="flex-1 flex flex-col truncate">
                              <p className="truncate text-sm font-medium leading-5 text-gray-900">
                                {currentTenant?.user?.name}
                              </p>
                              <p className="truncate text-sm font-light text-gray-900">
                                {currentTenant?.user?.email}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="border-t w-full my-2"></div>

                        {tenants?.length > 1 && (
                          <>
                            <TenantListPicker />
                            <div className="border-t w-full mb-2 mt-1"></div>{" "}
                          </>
                        )}

                        {menuNavigation.map((item) => (
                          <MenuItem key={item.name}>
                            <NavLink
                              to={item.href}
                              className={({ isActive }) =>
                                clsx(
                                  isActive
                                    ? "bg-gray-50 text-primary-600"
                                    : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold truncate"
                                )
                              }
                            >
                              <item.icon className="h-6 w-6 shrink-0" />
                              {item.name}
                            </NavLink>
                          </MenuItem>
                        ))}
                        <div className="border-t w-full my-2"></div>
                        <MenuItem as="div">
                          <button
                            type="button"
                            className="text-gray-700 hover:text-primary-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                            onClick={async () => {
                              navigate("/sign-out");
                            }}
                          >
                            <ArrowRightStartOnRectangleIcon className="h-6 w-6 shrink-0" />
                            Sign Out
                          </button>
                        </MenuItem>
                      </MenuItems>
                    </>
                  )}
                </Menu>
              </div>

              {/* Small screens  */}
              <div className="flex sm:hidden items-center gap-x-4 lg:gap-x-6">
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  {({ open }) => (
                    <>
                      <MenuButton className="-m-1.5 flex items-center p-1.5 group rounded-xl cursor-pointer">
                        <div className="flex items-center sm:items-start group-hover:opacity-70">
                          <div className="flex flex-col gap-1">
                            <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-200">
                              {currentTenant?.user?.picture ? (
                                <img
                                  referrerPolicy="no-referrer"
                                  src={currentTenant.user.picture}
                                />
                              ) : (
                                <DefaultAvatar />
                              )}
                            </span>
                          </div>

                          <ChevronDownIcon
                            className={clsx(
                              "h-5 w-5 text-gray-400 transition-all ml-1",
                              open && "scale-[-1]"
                            )}
                          />
                        </div>
                      </MenuButton>

                      <MenuItems
                        transition
                        anchor="bottom end"
                        className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-20 w-64 rounded-md bg-white py-2 px-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                      >
                        <div className="px-2">
                          <div className="flex items-center gap-2">
                            <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-200">
                              {currentTenant?.user?.picture ? (
                                <img
                                  referrerPolicy="no-referrer"
                                  src={currentTenant.user.picture}
                                />
                              ) : (
                                <DefaultAvatar />
                              )}
                            </span>

                            <div className="flex-1 flex flex-col truncate">
                              <p className="truncate text-sm font-medium leading-5 text-gray-900">
                                {currentTenant?.user?.name}
                              </p>
                              <p className="truncate text-sm font-light text-gray-900">
                                {currentTenant?.user?.email}
                              </p>
                              <div className="w-full">
                                <SubscriptionPill
                                  subscription={currentTenant?.subscription}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border-t w-full my-2"></div>

                        {tenants?.length > 1 && (
                          <>
                            <TenantListPicker />
                            <div className="border-t w-full mb-2 mt-1"></div>{" "}
                          </>
                        )}

                        {menuNavigation.map((item) => (
                          <MenuItem key={item.name}>
                            <NavLink
                              to={item.href}
                              className={({ isActive }) =>
                                clsx(
                                  isActive
                                    ? "bg-gray-50 text-primary-600"
                                    : "text-gray-700 hover:text-primary-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold truncate"
                                )
                              }
                            >
                              <item.icon className="h-6 w-6 shrink-0" />
                              {item.name}
                            </NavLink>
                          </MenuItem>
                        ))}
                        <div className="border-t w-full my-2"></div>
                        <MenuItem as="div">
                          <button
                            type="button"
                            className="text-gray-700 hover:text-primary-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full"
                            onClick={async () => {
                              navigate("/sign-out");
                            }}
                          >
                            <ArrowRightStartOnRectangleIcon className="h-6 w-6 shrink-0" />
                            Sign Out
                          </button>
                        </MenuItem>
                      </MenuItems>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
