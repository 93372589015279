import { Link, useNavigate } from "react-router-dom";
import SocialSet from "../../../../models/entities/social-set";
import { getDescription } from "../../../../utils/social-set-utils";
import useSelectSocialSet from "../../../../hooks/useSelectSocialSet";
import useSelectChannel from "../../../../hooks/useSelectChannel";
import { TrashIcon } from "@heroicons/react/24/outline";
import DeleteDialog from "../../../../components/dialogs/DeleteDialog";
import { getChannelTypeImageUrlFromType } from "../../../../data/channels-config";
import { useContext, useRef, useState } from "react";
import { GetSocialSetStatsResponse } from "../../../../models/api-responses/get-social-set-stats";
import alertService from "../../../../services/alert/alert-service";
import socialSetsService from "../../../../services/api/social-sets-service";
import { refetchQuery } from "../../../../queries/query-utils";
import queryNames from "../../../../queries/query-names";
import actionTypes from "../../../../state/global-state/action-types";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import Spinner from "../../../../components/common/Spinner";
import clsx from "clsx";

export default function List({ socialSets }: { socialSets: SocialSet[] }) {
  const selectSocialSet = useSelectSocialSet();
  const selectChannel = useSelectChannel();
  const navigate = useNavigate();

  const [isLoadingStats, setIsLoadingStats] = useState<boolean>(false);
  const [deletingSocialSet, setDeletingSocialSet] = useState<SocialSet>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [socialSetStats, setSocialSetStats] =
    useState<GetSocialSetStatsResponse>();

  const deleteDialogRef = useRef(null);
  const { dispatch } = useContext(GlobalStateContext);

  const onSelectSocialSet = (socialSet: SocialSet) => {
    selectSocialSet(socialSet);
    selectChannel();
  };

  const onDelete = async (socialSet: SocialSet) => {
    if (socialSets.length == 1) {
      alertService.info("You must keep at least one social set");
      return;
    }

    setDeletingSocialSet(socialSet);

    deleteDialogRef.current.openDialog();

    if (socialSet.channels.length) {
      setIsLoadingStats(true);

      try {
        const stats = await socialSetsService.getStats(socialSet.id);
        setSocialSetStats(stats);
      } finally {
        setIsLoadingStats(false);
      }
    }
  };

  const deleteSocialSetConfirmed = async () => {
    setIsDeleting(true);

    try {
      await socialSetsService.delete(deletingSocialSet.id.toString());
      await refetchQuery([queryNames.socialSets]);

      alertService.success("Social set successfully deleted");

      dispatch({
        type: actionTypes.SET_CURRENT_SOCIAL_SET,
        payload:
          socialSets.filter((x) => x.id != deletingSocialSet.id)?.[0] ?? null,
      });

      navigate(`/social-sets`, { replace: true });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <div>
        <ul
          role="list"
          className={clsx(
            "flex flex-col gap-5",
            isDeleting && "opacity-80 pointer-events-none"
          )}
        >
          {socialSets.map((socialSet) => (
            <li
              key={socialSet.id}
              className="col-span-1 flex rounded-md shadow-xs group"
            >
              <Link
                to={`${socialSet.id}`}
                className="flex flex-1 truncate"
                onClick={() => onSelectSocialSet(socialSet)}
              >
                <div
                  style={{ backgroundColor: socialSet.colorHex }}
                  className="flex flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white w-16"
                >
                  {socialSet.code}
                </div>
                <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white hover:bg-gray-50">
                  <div className="flex-1 truncate px-4 py-2 text-sm">
                    <div className="font-medium text-gray-900 hover:text-gray-600 truncate">
                      {socialSet.name}
                    </div>
                    <p className="text-gray-500">{getDescription(socialSet)}</p>
                  </div>

                  <div className="pr-4">
                    {isDeleting && deletingSocialSet?.id == socialSet.id ? (
                      <Spinner />
                    ) : (
                      <div>
                        <TrashIcon
                          data-tooltip-id="tooltip-placeholder"
                          data-tooltip-content="Delete social set"
                          className="w-5 h-5 text-gray-400 hover:text-red-600"
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            onDelete(socialSet);

                            return false;
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <DeleteDialog
        title="Delete social set"
        ref={deleteDialogRef}
        disabled={isLoadingStats}
        onConfirm={deleteSocialSetConfirmed}
      >
        {isLoadingStats ? (
          <div className="pr-10 py-5">
            <Spinner />
          </div>
        ) : deletingSocialSet?.channels.length &&
          socialSetStats?.channels?.length ? (
          <div>
            <div>
              <div className="text-sm mb-4">
                The following social profiles and their posts will be deleted
                and any scheduled posts within this social set will be canceled:
              </div>
              <ul className="space-y-4 mt-2">
                {socialSetStats.channels.map((x) => (
                  <li
                    key={x.channelId}
                    className="flex items-center min-w-0 gap-x-4"
                  >
                    <div className="relative">
                      <img
                        referrerPolicy="no-referrer"
                        className="h-8 w-8 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                        src={
                          x.channelPictureUrl ??
                          getChannelTypeImageUrlFromType(x?.channelType)
                        }
                      />
                      <img
                        className="h-5 w-5 flex-none rounded-full border border-white bg-gray-50 absolute top-3.5 right-0 -mr-2"
                        src={getChannelTypeImageUrlFromType(x?.channelType)}
                      />
                    </div>
                    <div className="overflow-hidden flex-1 text-sm leading-0 text-gray-800 text-ellipsis">
                      {x.channelName}
                      <span className="ml-2 text-xs leading-5 text-gray-500 font-bold whitespace-nowrap">
                        ({x.postsCount} posts)
                      </span>
                    </div>

                    {/* <span className="text-sm font-semibold leading-3 text-gray-900 truncate">{x.channelName}</span>
                    <span className="text-xs leading-5 text-gray-500 font-bold whitespace-nowrap">
                      ({x.postsCount} posts)
                    </span> */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div>Are you sure you want to delete the social set?</div>
        )}
      </DeleteDialog>
    </>
  );
}
