import { useContext } from "react";
import actionTypes from "../state/global-state/action-types";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import SocialSet from "../models/entities/social-set";
import useCurrentSocialSet from "./useCurrentSocialSet";
import { invalidateQuery } from "../queries/query-utils";
import queryNames from "../queries/query-names";

const useSelectSocialSet = () => {
  const { dispatch } = useContext(GlobalStateContext);
  const currentSocialSet = useCurrentSocialSet();

  return (socialSet?: SocialSet) => {
    if (socialSet.id == currentSocialSet?.id) {
      return;
    }

    invalidateQuery([queryNames.postQuotas]);

    dispatch({
      type: actionTypes.SET_CURRENT_SOCIAL_SET,
      payload: socialSet,
    });
  };
};

export default useSelectSocialSet;
