/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import TenantUpdateFormInput from "../../../../../models/form-inputs/tenant-form-input";
import InputField from "../../../../../components/form/InputField";
import Button from "../../../../../components/common/Button";
import ValidationMessages from "../../../../../data/validation-messages";

const schema = yup
  .object({
    name: yup
      .string()
      .required(ValidationMessages.WorkspaceNameRequired)
      .max(40, ValidationMessages.WorkspaceNameLength(40)),
  })
  .required();

export default function EditForm({
  input,
  onSubmit,
  isSubmitting,
}: {
  initialColor?: string;
  input?: TenantUpdateFormInput;
  onSubmit: (input: TenantUpdateFormInput) => void;
  isSubmitting: boolean;
}) {
  const navigate = useNavigate();

  const form = useForm<TenantUpdateFormInput>({
    resolver: yupResolver(schema) as any,
    mode: "all",
    defaultValues: input,
  });

  const submitForm = () => {
    form.handleSubmit(onSubmit)();
  };

  return (
    <>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex-1">
        <div className="max-w-sm">
          <div className="py-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-1">
              <div className="sm:col-span-3">
                <InputField
                  label="Name"
                  name="name"
                  formReturn={form}
                  maxLength={40}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="border-t border-gray-200 bg-white pt-5">
        <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className=""></div>
          <div className="flex-shrink-0 gap-2 flex">
            <Button
              variant="text"
              color="brand"
              className="w-24"
              text="Cancel"
              onClick={() => navigate(-1)}
            />
            <Button
              text="Save"
              variant="solid"
              color="brand"
              className="w-24"
              type="button"
              onClick={submitForm}
              loading={isSubmitting}
            />
          </div>
        </div>
      </div>
    </>
  );
}
