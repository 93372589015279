/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import {
  ChevronDownIcon,
  EllipsisHorizontalIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../../components/common/Button";
import {
  LocalizationProvider,
  StaticDateTimePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ClockIcon,
  DocumentTextIcon,
  PencilIcon,
  PlusIcon,
  RocketLaunchIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ValidationResult } from "../models/validation-result";
import Spinner from "../../../../components/common/Spinner";
import ErrorsAlertContainer from "./ErrorsAlertContainer";
import { PostInstance } from "../models/post-instance";
import alertService from "../../../../services/alert/alert-service";
import useAuthorizedAccess from "../../../../hooks/useAuthorizedAccess";
import TimezoneLabel from "../../../../components/common/TimezoneLabel";
import useCurrentSocialSet from "../../../../hooks/useCurrentSocialSet";

export interface ScheduleButtonsProps {
  dateTime?: Date;
  loading: boolean;
  isEdit: boolean;
  postInstances: PostInstance[];
  onDateTimeChanged: (dateTime: Date) => void;
  onSaveDraft: () => Promise<void>;
  onSchedulePost: () => Promise<void>;
  onPublishNow: () => Promise<void>;
  onScheduleNext: () => Promise<void>;
  onAddToQueue: () => Promise<void>;
  onSubmitAttempted: () => void;
  onCancel: () => void;
}

export default function ScheduleButtons({
  dateTime,
  postInstances,
  onSaveDraft,
  onSchedulePost,
  onPublishNow,
  // onScheduleNext,
  onAddToQueue,
  onDateTimeChanged,
  onSubmitAttempted,
  onCancel,
}: ScheduleButtonsProps) {
  const canPublish = useAuthorizedAccess(["schedule_posts_publish"], [], []);
  const socialSet = useCurrentSocialSet();
  const buttonRef = useRef();
  const [scheduledDate, setScheduledDate] = useState<Dayjs>(null);
  const [isSubmittingPrimary, setIsSubmittingPrimary] =
    useState<boolean>(false);
  const [isSubmittingSecondary, setIsSubmittingSecondary] =
    useState<boolean>(false);

  useEffect(() => {
    setScheduledDate(dateTime ? dayjs(dateTime).tz(socialSet.timezone) : null);
  }, [dateTime, socialSet.timezone]);

  const canSave = useMemo(
    () => postInstances.every((x) => x.validation.isValid),
    [postInstances]
  );

  const channelWithoutQueue = useMemo(
    () =>
      postInstances.filter(
        (x) =>
          !socialSet.channels.find((c) => c.id == x.channel.id)?.slots?.length
      ),
    [postInstances, socialSet.channels]
  );

  const validation = useMemo(() => {
    const errors = [
      ...new Set(postInstances.flatMap((x) => x.validation.errors)),
    ];

    return {
      isValid: postInstances.every((x) => x.validation.isValid),
      errors: errors,
    } as ValidationResult;
  }, [postInstances]);

  const onDateSelected = (day: Dayjs) => {
    setScheduledDate(day);
    onDateTimeChanged(day.toDate());

    // Has to be called twice, otherwise it doesn't close the calendar popover
    (buttonRef.current as any)?.click();
    (buttonRef.current as any)?.click();
  };

  const schedulePost = async () => {
    onSubmitAttempted();

    if (!canSave) {
      return;
    }

    if (
      !scheduledDate ||
      scheduledDate.isBefore(dayjs().tz(socialSet.timezone))
    ) {
      alertService.info("Please select a future date to schedule the post.");
      return;
    }

    try {
      setIsSubmittingPrimary(true);
      await onSchedulePost();
    } catch {
      // ignore
    } finally {
      setIsSubmittingPrimary(false);
    }
  };

  const saveDraft = async () => {
    onSubmitAttempted();

    if (!canSave) {
      return;
    }

    try {
      setIsSubmittingSecondary(true);
      await onSaveDraft();
    } catch {
      // ignore
    } finally {
      setIsSubmittingSecondary(false);
    }
  };

  const publishNow = async () => {
    onSubmitAttempted();

    if (!canSave) {
      return;
    }

    try {
      setIsSubmittingSecondary(true);
      await onPublishNow();
    } catch {
      // ignore
    } finally {
      setIsSubmittingSecondary(false);
    }
  };

  // const scheduleNext = async () => {
  //   alertService.info("This feature is not yet implemented.");
  //   return;

  //   onSubmitAttempted();

  //   if (!canSave) {
  //     return;
  //   }

  //   try {
  //     setIsSubmittingPrimary(true);
  //     await onScheduleNext();
  //   } catch {
  //     // ignore
  //   } finally {
  //     setIsSubmittingPrimary(false);
  //   }
  // };

  const addToQueue = async () => {
    onSubmitAttempted();

    if (!canSave) {
      return;
    }

    try {
      setIsSubmittingPrimary(true);
      await onAddToQueue();
    } catch {
      // ignore
    } finally {
      setIsSubmittingPrimary(false);
    }
  };

  const moreOptionsComponent = (
    <Menu as="div" className="inline-block relative text-left">
      <div className="mr-3 relative">
        <MenuButton as="div" className="">
          <Button
            className="shadow border border-gray-50"
            color="white"
            text=""
            variant="text"
            type="button"
            disabled={isSubmittingPrimary}
            loading={isSubmittingSecondary}
            icon={<EllipsisHorizontalIcon width={20} color="gray" />}
          />
        </MenuButton>
      </div>

      <MenuItems
        transition
        anchor="top end"
        className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-50 w-52 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      >
        <div className="py-1 px-1">
          <MenuItem>
            {({ focus }) => (
              <button
                onClick={() => publishNow()}
                className={clsx(
                  focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  "group flex items-center px-4 py-2 text-sm w-full"
                )}
              >
                <RocketLaunchIcon
                  className={clsx(
                    canSave ? "group-hover:text-gray-500" : "",
                    "mr-3 h-5 w-5 text-gray-400"
                  )}
                />
                Publish Now
              </button>
            )}
          </MenuItem>

          <MenuItem>
            {({ focus }) => (
              <button
                onClick={() => saveDraft()}
                className={clsx(
                  focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  "group flex items-center px-4 py-2 text-sm w-full"
                )}
              >
                <DocumentTextIcon
                  className={clsx(
                    canSave ? "group-hover:text-gray-500" : "",
                    "mr-3 h-5 w-5 text-gray-400"
                  )}
                />
                Save as Draft
              </button>
            )}
          </MenuItem>
          <MenuItem>
            {({ focus }) => (
              <button
                onClick={onCancel}
                className={clsx(
                  focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  "group flex items-center px-4 py-2 text-sm w-full"
                )}
              >
                <XMarkIcon className="group-hover:text-gray-500 mr-3 h-5 w-5 text-gray-400" />
                Cancel
              </button>
            )}
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );

  const dateTimePickerComponent = (
    <Popover className="relative">
      {() => (
        <>
          <PopoverButton as="div" ref={buttonRef}>
            <div className="flex flex-row items-center gap-2 text-sm text-slate-600 leading-tight tracking-tighter">
              <div className="flex sm:hidden items-center gap-1">Scheduled</div>
              <div className="hidden sm:flex items-center gap-1">
                Scheduled for
              </div>
              <div className="flex items-center gap-2 font-bold hover:underline cursor-pointer">
                {dayjs(scheduledDate)
                  .tz(socialSet.timezone)
                  .format("MMM DD, YYYY, hh:mm a")}
                &nbsp;
                <button
                  type="button"
                  className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <PencilIcon className="w-3" />
                </button>
              </div>
            </div>
          </PopoverButton>

          <PopoverPanel
            transition
            anchor="top end"
            className="z-50 overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 transition duration-200 ease-in-out [--anchor-gap:1rem] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
          >
            <div className="">
              <StaticDateTimePicker
                orientation="portrait"
                timezone={socialSet.timezone}
                value={scheduledDate}
                ampmInClock={false}
                onAccept={onDateSelected}
                disablePast={true}
                displayStaticWrapperAs="mobile"
                onClose={() => (buttonRef.current as any)?.click()}
                // slots={{
                //   calendarHeader: (props) => {
                //     console.log(props);
                //     return null;
                //   },
                //   toolbar: (props) => {
                //     console.log(props);
                //     return null;
                //   },
                // }}
              />
              <div className="absolute bottom-0 left-0 pb-4 pl-2">
                <TimezoneLabel />
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );

  const scheduleButtonComponent = (
    <>
      <ErrorsAlertContainer validation={validation} size={3.5}>
        {() => {
          return (
            <>
              <Menu as="div" className="inline-block relative text-left">
                <div className="flex flex-row relative">
                  <Button
                    className={clsx(
                      "relative group md:px-4 rounded-r-none pr-0",
                      !validation.isValid ? "opacity-50 cursor-not-allowed" : ""
                    )}
                    color="brand"
                    text=""
                    type="button"
                    disabled={isSubmittingSecondary || isSubmittingPrimary}
                    loading={isSubmittingPrimary}
                    onClick={schedulePost}
                  >
                    <div className="flex items-center">
                      <div>
                        {!isSubmittingPrimary ? (
                          <div className="flex items-center gap-2 divide-x">
                            <div className="flex items-center whitespace-nowrap">
                              <ClockIcon className="w-5 mr-2" />

                              <span>Schedule</span>
                              <span className="hidden md:block">
                                &nbsp;Post
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="flex gap-2 whitespace-nowrap">
                              Scheduling <Spinner size="5" />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Button>

                  {/* Queue Actions Button */}
                  <MenuButton
                    as="div"
                    onClick={(e) => {
                      if (!validation.isValid) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                  >
                    <Button
                      className={clsx(
                        "rounded-l-none pl-2 pr-2 border-l border-primary-400",
                        !validation.isValid
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      )}
                      color="brand"
                      text=""
                      disabled={isSubmittingPrimary || isSubmittingSecondary}
                      icon={<ChevronDownIcon className="w-5 text-white" />}
                    ></Button>
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  anchor="top end"
                  className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-50 w-52 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                >
                  <div className="py-1 px-1">
                    {/* <MenuItem>
                      {({ focus }) => (
                        <button
                          disabled={
                            isSubmittingPrimary || isSubmittingSecondary
                          }
                          onClick={() => scheduleNext()}
                          className={clsx(
                            focus
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm w-full"
                          )}
                        >
                          <ForwardIcon
                            className={clsx(
                              canSave ? "group-hover:text-gray-500" : "",
                              "mr-3 h-5 w-5 text-gray-400"
                            )}
                          />
                          Schedule Next
                        </button>
                      )}
                    </MenuItem> */}

                    <MenuItem>
                      {({ focus }) => (
                        <button
                          disabled={
                            isSubmittingPrimary || isSubmittingSecondary
                          }
                          onClick={() => addToQueue()}
                          className={clsx(
                            focus
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "group flex items-center px-4 py-2 text-sm w-full"
                          )}
                        >
                          <PlusIcon
                            className={clsx(
                              canSave ? "group-hover:text-gray-500" : "",
                              "mr-3 h-5 w-5 text-gray-400"
                            )}
                          />
                          Add to Queue
                          {channelWithoutQueue.length > 0 && (
                            <ExclamationCircleIcon
                              className="w-4 ml-auto text-yellow-600"
                              data-tooltip-id="tooltip-placeholder"
                              data-tooltip-html={`The following profile(s) have no posting slots configured, <br>so Posts added to their queue will be saved as Drafts: <br>${channelWithoutQueue
                                .map((x) => x.channel.type)
                                .join(", ")}`}
                            />
                          )}
                        </button>
                      )}
                    </MenuItem>
                  </div>
                </MenuItems>
              </Menu>
            </>
          );
        }}
      </ErrorsAlertContainer>
    </>
  );

  const saveAsDraftButtonComponent = (
    <>
      <ErrorsAlertContainer validation={validation} size={3.5}>
        {() => {
          return (
            <>
              <Button
                className={clsx(
                  "relative group md:px-4",
                  !validation.isValid ? "opacity-50 cursor-not-allowed" : ""
                )}
                color="brand"
                text=""
                type="button"
                disabled={isSubmittingSecondary}
                loading={isSubmittingSecondary}
                onClick={saveDraft}
              >
                <div className="flex items-center gap-2">
                  {!isSubmittingSecondary ? (
                    <div className="flex items-center">
                      <DocumentTextIcon className="w-5 mr-2" />
                      <span>Save as Draft</span>
                    </div>
                  ) : (
                    <>
                      Saving <Spinner size="5" />
                    </>
                  )}
                </div>
              </Button>
            </>
          );
        }}
      </ErrorsAlertContainer>
    </>
  );

  return (
    <>
      <div className="flex flex-col w-full justify-end gap-3">
        {scheduledDate && (
          <div className="flex flex-row justify-end gap-2 mb-1">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {dateTimePickerComponent}
            </LocalizationProvider>
          </div>
        )}
        <div className="ml-auto">
          <div className="flex flex-row">
            {canPublish && (
              <>
                {moreOptionsComponent}
                {scheduledDate ? scheduleButtonComponent : null}
              </>
            )}

            {!canPublish && <>{saveAsDraftButtonComponent}</>}
          </div>
        </div>
      </div>
    </>
  );
}
