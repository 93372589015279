import { useState } from "react";
import dayjs from "dayjs";
import stripeService from "../../../../services/api/stripe-service";
import StripeSubscription from "../../../../models/stripe/subscription";
import Spinner from "../../../../components/common/Spinner";
import { AlertCircle, ExternalLinkIcon } from "lucide-react";

export interface PastDueBannerProps {
  subscription?: StripeSubscription;
}

export default function PastDueBanner({ subscription }: PastDueBannerProps) {
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);

  const openBillingPortal = async () => {
    setIsLoadingPortal(true);

    try {
      const returnUrl = `${window.location.origin}/settings/payment-methods`;
      const customerPortalSession = await stripeService.getCustomerPortal(
        returnUrl
      );

      window.location.href = customerPortalSession.url;
    } catch {
      setIsLoadingPortal(false);
    }
  };

  return (
    <div className="bg-red-700 text-white px-4 py-3">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
        <div className="flex items-center space-x-3">
          <div className="flex-shrink-0">
            <AlertCircle className="h-6 w-6 text-primary-200 animate-pulse" />
          </div>
          <div className="flex-1">
            <p className="text-sm text-red-200 mt-0.5">
              <span className="text-sm font-semibold text-red-100">
                Subscription renewal failed.
              </span>{" "}
              Please update your payment method before{" "}
              <span className="font-semibold text-red-100">
                {" "}
                {dayjs(subscription.currentPeriodStart)
                  .add(15, "days")
                  .format("MMM DD, YYYY")}
              </span>{" "}
              to avoid service interruptions.
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={openBillingPortal}
            className="w-full sm:w-auto px-6 py-1.5 bg-white text-primary-600 text-sm font-semibold 
                     rounded-full hover:bg-gray-50 transition-colors duration-150
                     flex items-center justify-center space-x-2"
          >
            {!isLoadingPortal && (
              <>
                <div className="flex items-center gap-2 cursor-pointer whitespace-nowrap">
                  <ExternalLinkIcon className="h-4 w-4" />
                  <span>Pay Now</span>
                </div>
              </>
            )}
            {isLoadingPortal && (
              <>
                <div className="flex items-center gap-2">
                  Redirecting
                  <Spinner size="5" />
                </div>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
