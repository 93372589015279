import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import userService from "../../../../services/api/user-service";
import queryNames from "../../../../queries/query-names";
import UserUpdateFormInput from "../../../../models/form-inputs/user-update-input";
import { invalidateQuery } from "../../../../queries/query-utils";
import ErrorMessage from "../../../../components/common/ErrorMessage";
import Spinner from "../../../../components/common/Spinner";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import actionTypes from "../../../../state/global-state/action-types";
import EditUserForm from "./EditUserForm";
import NotFound from "../../../../components/common/404";

export default function EditUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { dispatch } = useContext(GlobalStateContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const query = useQuery({
    queryKey: [queryNames.users],
    queryFn: () => userService.list(),
  });

  const { isLoading, isError, data: users, error } = query;
  const user = users?.find((x) => x.id == id);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SETTINGS_BREADCRUMBS,
      payload: [
        {
          name: "Account Settings",
          to: "/settings",
        },
        {
          name: "Team Members",
          to: "/settings/team",
        },
        {
          name: user?.name ?? (isLoading ? "" : "Not Found"),
          to: `/settings/team/${id}`,
        },
        {
          name: "Edit",
          to: `/settings/team/${id}/edit`,
        },
      ],
    });
  }, [dispatch, id, user?.name, isLoading]);

  let content = null;

  const onSubmit = async (input: UserUpdateFormInput) => {
    try {
      setIsSubmitting(true);

      await userService.update(id, input);

      invalidateQuery([queryNames.users]);
      navigate(-1);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    content = <Spinner />;
  } else if (isError) {
    content = (
      <ErrorMessage
        error={error}
        onRetry={() => {
          query.refetch();
        }}
      />
    );
  } else if (user) {
    const input: UserUpdateFormInput = {
      name: user.name,
      email: user.email,
      socialSetIds: user.socialSetIds,
      permissions: user.permissions,
    };

    content = (
      <EditUserForm
        input={input}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        isEdit={true}
      />
    );
  } else {
    content = <NotFound flat={true} />;
  }

  return (
    <>
      <div className="flex flex-col justify-center bg-white shadow sm:rounded-lg min-h-[465px] px-4 py-5 sm:px-6">
        {content}
      </div>
    </>
  );
}
