import { ArrowRightIcon } from "@heroicons/react/20/solid";
import intercomService from "../../../../services/application/intercom-service";

export default function EntetprisePricing() {
  const openChat = () => {
    intercomService.openNewMessage();
  };

  return (
    <div className="relative">
      <div
        className="absolute inset-0 transform -skew-y-2 rounded-3xl shadow-xl"
        style={{ background: "#111827" }}
      >
        <div className="absolute"></div>
      </div>
      <div className="relative px-8 py-12 sm:px-12 lg:px-16 flex flex-col lg:flex-row items-center justify-between">
        <div className="text-center lg:text-left mb-8 lg:mb-0">
          <div className="flex items-center justify-center lg:justify-start mb-4">
            <span className="text-[#e03058] text-lg font-semibold">
              Enterprise Plan
            </span>
          </div>
          <h2 className="text-3xl font-bold text-white mb-4">
            Need a custom solution?
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl">
            Get a tailored plan that perfectly fits your organization's needs.
            Our enterprise solutions include unlimited resources, dedicated
            support, custom integrations, and flexible terms.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 lg:ml-8">
          <button
            type="button"
            onClick={openChat}
            id="contact-sales"
            className="group border-white border px-8 py-4 rounded-xl text-white font-semibold hover:bg-primary-600 hover:border-primary-600 transition-colors flex items-center justify-center shadow-md hover:shadow-lg"
            style={{ cursor: "pointer" }}
          >
            Contact Sales
            <ArrowRightIcon className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
          </button>
        </div>
      </div>
    </div>
  );
}
