import { useContext, useMemo } from "react";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import TenantUser from "../models/entities/tenant-user";

const useTenants = (): TenantUser[] => {
  const { state } = useContext(GlobalStateContext);

  const tenants = useMemo(() => {
    return state?.tenants ?? [];
  }, [state.tenants]);

  return tenants;
};

export default useTenants;
