import dayjs from "dayjs";
import { Fragment, useEffect, useRef, useState } from "react";
import { generateInstantiatedSlots } from "../../../../utils/channel-slot-utils";
import groupBy from "../../../../utils/list-utils";
import ChannelSlotInstance from "./models/channel-slot-instance";
import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../../queries/query-names";
import useCurrentSocialSet from "../../../../hooks/useCurrentSocialSet";
import Spinner from "../../../../components/common/Spinner";
import PostPreviewTile from "../../calendar/components/views/PostPreviewTile";
import postQueueService from "../../../../services/api/post-queue-service";
import PostActions from "../../../shared/post-actions/PostActions";
import { OpenComposerParams } from "../../../../components/dialogs/CreatePostDialog";
import NoPosts from "../components/NoPosts";
import useCurrentChannel from "../../../../hooks/useCurrentChannel";
import TimezoneLabel from "../../../../components/common/TimezoneLabel";
import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useVirtualizer } from "@tanstack/react-virtual";

export default function ListQueue() {
  const channel = useCurrentChannel();
  const socialSet = useCurrentSocialSet();
  const [slotsByDate, setSlotsByDate] = useState<
    Record<string, ChannelSlotInstance[]>
  >({});
  const [ready, setReady] = useState<boolean>(false);
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: Object.keys(slotsByDate).length,
    getScrollElement: () => parentRef.current,
    estimateSize: (i) => {
      const slots = Object.entries(slotsByDate)[i][1];
      const slotsLength = slots.length;
      const occupiedSlots = slots.filter((x) => !!x.post).length;
      const emptySlots = slotsLength - occupiedSlots;

      return (
        24 +
        12 +
        12 * (slotsLength - 1) +
        200 * occupiedSlots +
        38 * emptySlots +
        32
      );
    },
    overscan: 5,
  });

  // const [unscheduledSlots, setUnscheduledSlots] = useState<
  //   ChannelSlotInstance[]
  // >([]);
  const postComposerRef = useRef(null);

  const { data: posts, isLoading } = useQuery({
    queryKey: [queryNames.queuedPosts, socialSet?.id, channel?.id],
    queryFn: () => postQueueService.listQueued(socialSet.id, channel?.id),
  });

  useEffect(() => {
    const today = dayjs().tz(socialSet.timezone);
    const startDay = today.startOf("day");

    const instantiatedSlots = generateInstantiatedSlots(
      startDay,
      channel?.slots,
      posts ?? [],
      socialSet.timezone,
      180,
      "Ascending"
    );

    const slotsByDate = groupBy(instantiatedSlots, "date");
    setSlotsByDate(slotsByDate);
    setReady(true);

    // const unscheduledSlots = posts
    //   .filter((x) => x.scheduledAt.toString() == "0001-01-01T00:00:00")
    //   .map((x) => {
    //     return {
    //       post: x,
    //       day: null,
    //       time: null,
    //       dayjsDate: null,
    //       id: x.id,
    //     } as ChannelSlotInstance;
    //   });

    // setUnscheduledSlots(unscheduledSlots);
  }, [channel?.slots, socialSet.timezone, posts]);

  const openComposerWithSlot = (slot: ChannelSlotInstance) => {
    openComposer({
      scheduledAt: slot.dayjsDate.toDate(),
      channelIds: [channel?.id].filter((x) => !!x),
    });
  };

  const openComposer = (params: OpenComposerParams) => {
    postComposerRef.current?.openDialog(params);
  };

  // const handleScroll = (e) => {
  //   const element = e.target;

  //   if (
  //     Math.abs(
  //       element.scrollHeight - (element.scrollTop + element.clientHeight)
  //     ) <= 1
  //   ) {
  //     console.log("Scrolled to bottom");
  //   }
  // };

  const configurePostingSlots = () => {
    return (
      <div className="flex justify-end">
        <Link
          to={`../settings`}
          className="flex gap-1 items-center underline text-gray-500 text-sm"
        >
          <Cog6ToothIcon className="w-4 h-4" /> Configure Posting Slots
        </Link>
      </div>
    );
  };

  return (
    <>
      <div
        className="flex flex-col overflow-hidden rounded-md shadow overflow-y-auto bg-white h-full"
        // onScroll={handleScroll}
      >
        <div className="lg:flex lg:flex-col">
          <div className="flex flex-col sm:flex-row gap-2 bg-white border-b sticky top-0 z-20 py-4 px-6">
            <div className="flex flex-col flex-1">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Scheduled Posts
              </h2>
              <p className="mt-1 text-sm text-gray-500 leading-4 max-w-2xl">
                Choose optimal time slots to schedule your posts.
              </p>
            </div>

            <TimezoneLabel />
          </div>

          <div className="px-8 py-6">
            <div className="flex flex-col gap-8 max-w-screen-md mx-auto">
              {isLoading && (
                <div className="mt-28">
                  <Spinner />
                </div>
              )}
              {!isLoading && ready && (
                <>
                  {/* {!channel.slots.length && (
                    <div className="">
                      <NoPosts
                        title="Queue not configured"
                        description="No posting slots have been configured for this social profile."
                        button={
                          channel && (
                            <Link
                              to={`../settings`}
                              className="underline mt-2 text-gray-500 text-sm"
                            >
                              Configure Posting Slots
                            </Link>
                          )
                        }
                      />
                    </div>
                  )} */}

                  {/* <div className="flex flex-col gap-3">
                    <div className="flex gap-1 items-center justify-center text-md font-semibold">
                      <span className="text-gray-800">Unscheduled</span>
                    </div>

                    <div className="flex flex-col gap-3">
                      <PostActions composerRef={postComposerRef}>
                        {({
                          deletePost,
                          isUpdating,
                          moveToDraft,
                          moveToScheduled,
                          publishNow,
                          editPost,
                          clonePost,
                        }) => (
                          <>
                            {unscheduledSlots.map((slot) => (
                              <Fragment key={slot.id}>
                                {!slot.post && (
                                  <div
                                    className="group hover:bg-gray-100 cursor-pointer rounded-md border p-2"
                                    onClick={() => openComposerWithSlot(slot)}
                                  >
                                    <div className="block group-hover:hidden text-center text-sm font-normal text-gray-800">
                                      {slot.dayjsDate.format("hh:mm A")}
                                    </div>

                                    <div className="hidden group-hover:block text-center text-sm font-medium text-gray-800">
                                      Schedule a post
                                    </div>
                                  </div>
                                )}
                                {slot.post && (
                                  <div className="rounded-md border">
                                    <PostPreviewTile
                                      metricsViewMode="hidden"
                                      event={{
                                        id: slot.post.id,
                                        name: slot.post.title,
                                        time: "time",
                                        datetime: "datetime",
                                        post: slot.post,
                                      }}
                                      flat={true}
                                      isSubmitting={isUpdating}
                                      onDelete={(event) =>
                                        deletePost(event.post)
                                      }
                                      onEdit={() => editPost(slot.post)}
                                      onClone={() => clonePost(slot.post)}
                                      publishNow={(event) =>
                                        publishNow(event.post)
                                      }
                                      moveToDraft={(event) =>
                                        moveToDraft(event.post)
                                      }
                                      moveToScheduled={(event) =>
                                        moveToScheduled(event.post)
                                      }
                                    />
                                  </div>
                                )}
                              </Fragment>
                            ))}
                          </>
                        )}
                      </PostActions>
                    </div>
                  </div> */}

                  {channel != null && configurePostingSlots()}

                  {!Object.entries(slotsByDate).length ? (
                    <>
                      {channel == null && (
                        <NoPosts
                          title="No posts in the queue"
                          description="No posts were found in any of the queues in the current social set."
                        />
                      )}

                      {channel != null && (
                        <NoPosts
                          title="Social profile queue not configured"
                          description="No posting slots have been configured for this social profile."
                          button={configurePostingSlots()}
                        />
                      )}
                    </>
                  ) : null}

                  <div ref={parentRef}>
                    <div
                      className="flex flex-col gap-8 w-full relative"
                      style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                      }}
                    >
                      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const [date, slots] =
                          Object.entries(slotsByDate)[virtualRow.index];

                        return (
                          <div key={date} className="flex flex-col gap-3">
                            <div className="flex gap-1 items-center justify-center text-md font-semibold">
                              {dayjs
                                .tz(date, socialSet.timezone)
                                .startOf("day")
                                .isSame(
                                  dayjs().tz(socialSet.timezone).startOf("day")
                                ) && (
                                <span className="text-gray-800">Today,</span>
                              )}
                              {dayjs
                                .tz(date, socialSet.timezone)
                                .startOf("day")
                                .isSame(
                                  dayjs()
                                    .tz(socialSet.timezone)
                                    .add(1, "day")
                                    .startOf("day")
                                ) && (
                                <span className="text-gray-800">
                                  Tomorrow,{" "}
                                </span>
                              )}
                              <span className="text-gray-800">
                                {dayjs
                                  .tz(date, socialSet.timezone)
                                  .format("dddd")}
                              </span>
                              <span className="text-gray-500">
                                {dayjs.tz(date, socialSet?.timezone).year() !=
                                dayjs().tz(socialSet?.timezone).year()
                                  ? dayjs
                                      .tz(date, socialSet.timezone)
                                      .format("DD MMMM YYYY")
                                  : dayjs
                                      .tz(date, socialSet.timezone)
                                      .format("DD MMMM")}
                              </span>
                            </div>

                            <div className="flex flex-col gap-3">
                              <PostActions composerRef={postComposerRef}>
                                {({
                                  deletePost,
                                  isUpdating,
                                  moveToDraft,
                                  moveToScheduled,
                                  publishNow,
                                  editPost,
                                  clonePost,
                                }) => (
                                  <>
                                    {slots.map((slot) => (
                                      <Fragment key={slot.id}>
                                        {!slot.post && (
                                          <div
                                            className="group hover:bg-gray-100 cursor-pointer rounded-md border p-2"
                                            onClick={() =>
                                              openComposerWithSlot(slot)
                                            }
                                          >
                                            <div className="block group-hover:hidden text-center text-sm font-normal text-gray-800">
                                              {slot.dayjsDate.format("hh:mm A")}
                                            </div>

                                            <div className="hidden group-hover:block text-center text-sm font-medium text-gray-800">
                                              Schedule a post
                                            </div>
                                          </div>
                                        )}
                                        {slot.post && (
                                          <div className="rounded-md border">
                                            <PostPreviewTile
                                              metricsViewMode="hidden"
                                              event={{
                                                id: slot.post.id,
                                                name: slot.post.title,
                                                time: "time",
                                                datetime: "datetime",
                                                post: slot.post,
                                              }}
                                              flat={true}
                                              isSubmitting={isUpdating}
                                              onDelete={(event) =>
                                                deletePost(event.post)
                                              }
                                              onEdit={() => editPost(slot.post)}
                                              onClone={() =>
                                                clonePost(slot.post)
                                              }
                                              publishNow={(event) =>
                                                publishNow(event.post)
                                              }
                                              moveToDraft={(event) =>
                                                moveToDraft(event.post)
                                              }
                                              moveToScheduled={(event) =>
                                                moveToScheduled(event.post)
                                              }
                                            />
                                          </div>
                                        )}
                                      </Fragment>
                                    ))}
                                  </>
                                )}
                              </PostActions>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
