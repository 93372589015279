export default function YouTubeInfoDialog() {
  return (
    <div className="text-sm text-gray-800 font-light">
      <p>You can connect multiple YouTube channels to Viraly.</p>

      <p className="mt-5">
        To connect a YouTube channel, first{" "}
        <span className="font-medium">log into the Google account</span> that
        owns the YouTube channel you want to link to Viraly. Then, grant Viraly
        permission to access this Google account.
      </p>
    </div>
  );
}
