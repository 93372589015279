import { useCallback, useContext } from "react";
import sessionService from "../services/application/session-service";
import actionTypes from "../state/global-state/action-types";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import Channel from "../models/entities/channel";

const useSelectChannel = () => {
  const { dispatch } = useContext(GlobalStateContext);
  const callback = useCallback(
    (channel?: Channel) => {
      dispatch({ type: actionTypes.SET_CURRENT_CHANNEL, payload: channel });
      sessionService.setCurrentChannel(channel?.id);
    },
    [dispatch]
  );

  return callback;
};

export default useSelectChannel;
