import dayjs from "dayjs";
import { Link } from "react-router-dom";
import StripeSubscription from "../../../../models/stripe/subscription";
import { AlertOctagon, ArrowRight } from "lucide-react";

export interface SubscriptionCanceledBannerProps {
  subscription?: StripeSubscription;
}

export default function SubscriptionCanceledBanner({
  subscription,
}: SubscriptionCanceledBannerProps) {
  return (
    <div className="bg-gray-900 text-white px-4 py-3">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3">
        <div className="flex items-center space-x-3">
          <div className="flex-shrink-0">
            <AlertOctagon className="h-6 w-6 text-primary-400" />
          </div>
          <div className="flex-1">
            <p className="text-sm text-gray-400 mt-0.5">
              Your{" "}
              <span className="font-medium">
                {subscription?.items?.[0]?.plan?.product?.name}
              </span>{" "}
              {subscription?.items?.[0]?.plan?.interval == "year"
                ? "yearly"
                : "monthly"}{" "}
              plan was canceled on{" "}
              <span className="font-medium">
                {" "}
                {dayjs(subscription.canceledAt).format("MMM DD, YYYY")}
              </span>
              . To continue using Viraly you have to subscribe again.
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Link
            to="/settings/subscription"
            className="w-full sm:w-auto px-6 py-1.5 bg-primary-600 text-white text-sm font-semibold 
        rounded-full hover:bg-primary-500 transition-colors duration-150 
        flex items-center justify-center space-x-2 group"
          >
            <div className="flex items-center gap-2 whitespace-nowrap">
              Choose Plan
              <ArrowRight className="h-4 w-4 group-hover:translate-x-1 transition-transform" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
