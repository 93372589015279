import dayjs from "dayjs";
import { Clock } from "lucide-react";
import { Link } from "react-router-dom";
import StripeSubscription from "../../../../models/stripe/subscription";

export interface TrialBannerProps {
  subscription?: StripeSubscription;
}

export default function TrialBannerTop({ subscription }: TrialBannerProps) {
  const days = !subscription
    ? 0
    : dayjs(subscription.trialEnd).add(1, "h").diff(dayjs(), "days");

  return (
    <div className="bg-gradient-to-r from-primary-600 to-primary-700 text-white px-4 py-3">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <Clock className="h-5 w-5" />
          <p className="text-sm font-medium">
            <span className="font-bold">
              {days} day{days === 1 ? "" : "s"}
            </span>{" "}
            left in your free trial.{" "}
            <span className="hidden sm:inline">
              Upgrade now to keep your posts, connected social profiles, and
              other data.
            </span>
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <Link
            to="/settings/subscription"
            className="px-4 py-1.5 bg-white text-primary-500 text-sm font-semibold rounded-full 
                     hover:bg-primary-50 transition-colors duration-150 whitespace-nowrap"
          >
            Activate Now
          </Link>

          {/* <button
            className="text-white opacity-70 hover:opacity-100 transition-opacity duration-150"
          >
            <X className="h-5 w-5" />
            <span className="sr-only">Dismiss</span>
          </button> */}
        </div>
      </div>
    </div>
  );
}
