/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import Button from "../../../../components/common/Button";

const ChannelInfoDialog = forwardRef(
  (
    {
      onConfirm,
      onCancel,
      title = "Connect Social Profile",
      confirmLabel = "Connect",
      cancelLabel = "Cancel",
      children,
    }: any,
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const cancelButtonRef = useRef(null);

    // Expose the openDialog and closeDialog methods to parent components
    useImperativeHandle(ref, () => ({
      openDialog: () => setIsOpen(true),
      closeDialog: () => setIsOpen(false),
    }));

    // Handle confirm action
    const handleConfirm = () => {
      setIsOpen(false);

      if (onConfirm) {
        onConfirm();
      }
    };

    // Handle cancel action
    const handleCancel = () => {
      setIsOpen(false);

      if (onCancel) {
        onCancel();
      }
    };

    return (
      <Transition as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setIsOpen}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80 z-50 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 flex w-screen items-center justify-center z-50">
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg ">
              <DialogTitle
                as="h3"
                className="text-base font-semibold leading-7 text-gray-900 border-b p-4"
              >
                {title}
              </DialogTitle>

              <div className="bg-gray-50 py-6 px-4">{children}</div>

              <div className="flex justify-end gap-2 border-t p-4">
                <Button
                  variant="solid"
                  color="white"
                  text={cancelLabel}
                  onClick={handleCancel}
                  ref={cancelButtonRef}
                />
                <Button
                  variant="solid"
                  color="red"
                  text={confirmLabel}
                  onClick={handleConfirm}
                />
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    );
  }
);

export default ChannelInfoDialog;
