import { useParams } from "react-router-dom";
import ChannelsList from "./components/ChannelsList";
import { useContext } from "react";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import NotFound from "../../../components/common/404";
import { canAccessSocialSet } from "../../../utils/auth-utils";
import Unauthorized from "../../../components/common/403";

export default function Channels() {
  const { id } = useParams();
  const { state } = useContext(GlobalStateContext);

  // Get all social sets
  const socialSets = state.socialSets ?? [];
  const socialSet = socialSets?.find((x) => x.id == id);

  let content = null;

  if (socialSet) {
    content = canAccessSocialSet(state.currentTenant, socialSet.id) ? (
      <ChannelsList socialSet={socialSet} showHeader={true} flat={true} />
    ) : (
      <Unauthorized flat={true} />
    );
  } else {
    content = <NotFound />;
  }

  return (
    <>
      <div>{content}</div>
    </>
  );
}
