import { useEffect, useRef, useState } from "react";
import useCurrentSocialSet from "../../../hooks/useCurrentSocialSet";
import { refetchQuery } from "../../../queries/query-utils";
import queryNames from "../../../queries/query-names";
import alertService from "../../../services/alert/alert-service";
import Channel from "../../../models/entities/channel";

const pollingInterval = 8000;

export default function ChannelSyncStatus() {
  const currentSocialSet = useCurrentSocialSet();

  // We'll track whether we are currently polling or not
  const [isPolling, setIsPolling] = useState(false);

  // We also track the previous channel states so we can detect changes
  // from syncStartedAt != null -> syncStartedAt == null
  const previousChannelsRef = useRef<{ [channelId: string]: string | null }>(
    {}
  );

  // 1) Check if we need to start or stop polling
  useEffect(() => {
    if (!currentSocialSet?.channels) return;

    const anySyncing = currentSocialSet.channels.some(
      (ch) => ch.syncStartedAt != null
    );

    // If any channel is syncing and we are not polling yet, start polling
    if (anySyncing && !isPolling) {
      setIsPolling(true);
    }

    // If no channel is syncing anymore but we are still polling, stop polling
    if (!anySyncing && isPolling) {
      setIsPolling(false);
    }
  }, [currentSocialSet, isPolling]);

  // 2) Actually set up the 3s polling interval based on `isPolling`
  useEffect(() => {
    const refetch = () => {
      refetchQuery([queryNames.socialSets]);
    };

    let intervalId = null;

    if (isPolling) {
      // Call refetch immediately if you want the first fetch right away
      // refetch();

      intervalId = setInterval(() => {
        refetch();
      }, pollingInterval);
    }

    // Cleanup the interval if we stop polling or unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPolling]);

  // 3) Detect when a channel changes from syncStartedAt != null to == null
  useEffect(() => {
    if (!currentSocialSet?.channels) return;

    const currentChannels = currentSocialSet.channels;

    // Build a map of old syncStartedAt values by channel ID
    const previousChannelsMap = previousChannelsRef.current;

    // We’ll check if at least one channel changed from != null to == null
    const syncedChannels = [];

    currentChannels.forEach((channel) => {
      const previousSyncStartedAt = previousChannelsMap[channel.id];
      const currentSyncStartedAt = channel.syncStartedAt;

      // If previously non-null, and now it's null, we trigger the alert
      if (previousSyncStartedAt != null && currentSyncStartedAt == null) {
        syncedChannels.push(channel);
      }
    });

    syncedChannels.forEach((channel: Channel) => {
      alertService.info(
        `Sync completed for ${channel.type} social profile "${channel.name}"`
      );
    });

    // Update the ref for next render:
    const newChannelsMap: { [channelId: string]: string | null } = {};
    currentChannels.forEach((ch) => {
      newChannelsMap[ch.id] = ch.syncStartedAt?.toString();
    });
    previousChannelsRef.current = newChannelsMap;
  }, [currentSocialSet]);

  return null;
}
