import { Outlet } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { useState } from "react";
import SubscriptionBanners from "./components/banners/SubscriptionBanners";
import useSubscription from "../../hooks/useSubscription";

export default function MainLayoutWithSideBar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { isTrialing, isPastDue, isCanceled } = useSubscription();
  const showSubscriptionBanner = isTrialing || isPastDue || isCanceled;
  const [bannerHeight, setBannerHeight] = useState(0);

  return (
    <>
      <div>
        {showSubscriptionBanner && (
          <SubscriptionBanners onHeightChange={setBannerHeight} />
        )}

        <div className="flex flex-row gap-4 h-dvh">
          {/* Sidebar with dynamic top padding */}
          <div
            className="hidden fixed h-full z-20 lg:inset-y-0 lg:z-50 lg:flex lg:w-64 lg:flex-col"
            style={{
              paddingTop: showSubscriptionBanner
                ? `${bannerHeight}px`
                : undefined,
            }}
          >
            <div className="relative h-full flex">
              <div className="h-full flex w-full">
                <Sidebar
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                />
              </div>
            </div>
          </div>

          {/* Main Content with dynamic top padding */}
          <main
            className="lg:pl-64 flex-1 max-w-full"
            style={{
              paddingTop: showSubscriptionBanner
                ? `${bannerHeight}px`
                : undefined,
            }}
          >
            <div className="px-4 py-4 sm:px-6 lg:py-5 flex flex-col h-full">
              <div className="sticky top-0 z-20 flex">
                <Header setSidebarOpen={setSidebarOpen} hasSidebar={true} />
              </div>
              <div className="mt-5 flex-1 flex flex-col xl:flex-row gap-6 w-full h-[calc(100%-127px)]">
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
