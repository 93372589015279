import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import GlobalStateContext from "../../../state/global-state/GlobalStateContext";
import actionTypes from "../../../state/global-state/action-types";
import Button from "../../../components/common/Button";
import useAuthorizedAccess from "../../../hooks/useAuthorizedAccess";
import Unauthorized from "../../../components/common/403";

export default function WorkspaceSettings() {
  const { dispatch, state } = useContext(GlobalStateContext);
  const isAuthorized = useAuthorizedAccess([], ["Owner"], []);
  const tenant = state.currentTenant;

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_SETTINGS_BREADCRUMBS,
      payload: [
        {
          name: "Account Settings",
          to: "/settings",
        },
        {
          name: "My Workspace",
          to: "/settings/workspace",
        },
      ],
    });
  }, [dispatch]);

  return (
    <>
      {!isAuthorized && <Unauthorized flat={true} />}

      {isAuthorized && (
        <div>
          <div className="flex items-center">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                My Workspace
              </h2>
              <p className="text-sm leading-6 text-gray-500">
                Workspace settings and information.
              </p>
            </div>

            <div className="ml-auto">
              <Button
                to="edit"
                variant="solid"
                color="brand"
                className="w-24"
                text="Edit"
              />
            </div>
          </div>

          <div className="my-4 border-t border-gray-200"></div>

          <dl className="py-5 mb-5 space-y-6 divide-y divide-gray-100 border-b border-b-gray-100 text-sm leading-6">
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Created at
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">
                  {dayjs(tenant.tenant.createdAt).format(
                    "MMM DD, YYYY, hh:mm a"
                  )}
                </div>
              </dd>
            </div>
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                Name
              </dt>
              <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <div className="text-gray-900">{tenant.tenant.name}</div>
              </dd>
            </div>
          </dl>
        </div>
      )}
    </>
  );
}
